import React from "react";
import { makeStyles } from "@material-ui/core";
import { h7Regular } from "../../../shared/constants";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckmarkIcon } from "../../../assets/checkmark.svg";
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: 230,
  },

  timeline: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },

  processState: {
    background: "#80BB34",
    width: 24,
    height: 24,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 12,
      width: 12,
    },
  },

  awaitingState: {
    background: "#15222F",
    width: 16,
    height: 16,
    borderRadius: "50%",
    opacity: 0.2,

    "@media (max-width:940px)": {
      height: 8,
      width: 8,
    },
  },

  line: {
    height: 2,
    width: 76,

    "@media (max-width:940px)": {
      width: 70,
    },
  },

  statesList: {
    marginTop: 12,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    color: "#15222F",
    opacity: 0.4,
    ...h7Regular,
  },
});

const OrderTimeLine = ({ state }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let caseIndex = 0;

  if (state === "Preparing") {
    caseIndex = 0;
  }
  if (state === "Shipping") {
    caseIndex = 1;
  }
  if (state === "Delivered") {
    caseIndex = 2;
  }

  return (
    <div className={classes.container}>
      <div className={classes.timeline}>
        <div className={classes.processState}>
          {caseIndex > 0 ? <CheckmarkIcon /> : ""}
        </div>
        <span
          className={classes.line}
          style={{
            background: caseIndex > 0 ? "#80BB34" : "#15222F",
            opacity: caseIndex > 0 ? 1 : 0.2,
          }}
        ></span>
        <div
          className={
            caseIndex > 0 ? classes.processState : classes.awaitingState
          }
        >
          {caseIndex > 1 ? <CheckmarkIcon /> : ""}
        </div>
        <span
          className={classes.line}
          style={{
            background: caseIndex > 1 ? "#80BB34" : "#15222F",
            opacity: caseIndex > 1 ? 1 : 0.2,
          }}
        ></span>
        <div
          className={
            caseIndex > 1 ? classes.processState : classes.awaitingState
          }
        >
          {caseIndex === 2 ? <CheckmarkIcon /> : ""}
        </div>
      </div>
      <div className={classes.statesList}>
        {[
          `${t("account.assembling")}`,
          `${t("account.onTheWay")}`,
          `${t("account.delivered")}`,
        ].map((state) => {
          return <span>{state}</span>;
        })}
      </div>
    </div>
  );
};

export default OrderTimeLine;
