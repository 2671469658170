import React, { useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import {
  languagesMapper,
  languagesOptions,
} from "./preferred-language.constants";
import { FETCH_LANGUAGE } from "../../../queries/user.queries";
import { UPDATE_LANGUAGE } from "../../../mutations/user.mutations";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { FireworkSpinner } from "react-spinners-kit";
import { useTranslation } from "react-i18next";

const PreferredLanguage = () => {
  const { t } = useTranslation();
  const firstUpdate = useRef(true);
  const [language, setLanguage] = useState(localStorage.getItem("lang") ?? "");
  const { loading, data, error } = useQuery(FETCH_LANGUAGE);
  let [
    updateLanguage,
    { loading: updateLanguageLoading, error: updateLanguageError },
  ] = useMutation(UPDATE_LANGUAGE, {
    variables: {
      input: {
        languageCode: languagesMapper[language],
      },
    },
    refetchQueries: [
      { query: GET_ACTIVE_USER }, // Query name
    ],
    awaitRefetchQueries: true,
  });

  const handleChange = (e) => {
    if (!e.target.value) return;
    setLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
  };

  React.useEffect(() => {
    if (language.trim() === "") return;
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    try {
      updateLanguage();
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  React.useEffect(() => {
    if (!data) return;
    if (data?.activeCustomer?.languageCode) {
      setLanguage(data?.activeCustomer?.languageCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const loader = (
    <div>
      <FireworkSpinner
        color={"#B73232"}
        size={20}
        loading={true}
        style={{ marginTop: 10 }}
      />
    </div>
  );

  return (
    <>
      <span
        style={{
          color: "#80BB34",
          fontSize: 17,
          fontStyle: "normal",
          fontFamily: "Barlow, Assistant",
          fontWeight: 500,
        }}
      >
        {t("account.editLanguage")}
      </span>
      <select
        value={language}
        onChange={handleChange}
        style={{
          maxWidth: 100,
          marginTop: 15,
          height: 50,
          borderRadius: 10,
          padding: 5,
        }}
      >
        {languagesOptions?.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>

      {(updateLanguageLoading && loader) || (loading && loader)}
      {error && <p>{error.message}</p>}
      {updateLanguageError && <p>{updateLanguageError.message}</p>}
    </>
  );
};

export default PreferredLanguage;
