import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  h2,
  h4Medium,
  h5Medium,
  h5Regular,
  pointer,
} from "../../../shared/constants";

import "../../../App.css";
import HomeProductsDesk from "../home-products/home-products.component";
import HomeProductsRes from "../home-products-res/home-products-res.component";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_COLLECTION } from "../../../queries/queries";
import { FilterContext } from "../../../context/filterContext";
import { useHistory } from "react-router";
// import { GET_COLLECTION, GET_COLLECTIONS } from "../../../queries/queries";

const useStyles = makeStyles({
  main: {
    paddingLeft: 40,
    "@media (max-width:940px)": {
      padding: "0px 16px",
    },
  },
  header: {
    marginTop: 120,
    paddingBottom: 10,
    display: "flex",
    overflowX: "scroll",
    scrollbarWidth: "3px",
    "@media (max-width:940px)": {
      marginTop: 56,
      marginBottom: 48,
      paddingBottom: 0,
    },
    "&::-webkit-scrollbar": {
      width: 5,
      height: 5,
    },
  },
  headerDivs: {
    ...h4Medium,
    ...pointer,
    opacity: 0.8,
    marginRight: 48,
    minWidth: "auto",
    whiteSpace: "nowrap",
    "@media (max-width:940px)": {
      ...h5Medium,
      marginBottom: 24,
    },
  },
  productsContainer: {
    margin: "48px 0 120px 0",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:940px)": {
      marginBottom: 24,
    },
  },
  description: {
    maxWidth: 427,
    display: "flex",
    flexDirection: "column",
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  title: {
    ...h2,
  },
  subTitle: {
    ...h5Regular,
    marginTop: 32,
  },
  button: {
    ...h5Medium,
    ...pointer,
    marginTop: 48,
    height: 56,
    maxWidth: 310,
    background: "#80BB34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: 16,
    "@media (max-width:940px)": {
      marginTop: 24,
    },
  },
  products: {
    width: "65%",
    display: "flex",
    overflowX: "auto",
    "@media (max-width:940px)": {
      display: "none",
    },
  },
  productsRes: {
    width: "100%",
    display: "flex",
    "@media (min-width:940px)": {
      display: "none",
    },
  },
  buttonRes: {
    ...h5Medium,
    ...pointer,
    height: 56,
    width: "100%",
    maxWidth: 350,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 auto",
    marginBottom: 56,
    background: "#80BB34",
    color: "#FFFFFF",
    border: "none",
    borderRadius: 16,
    "@media (min-width:940px)": {
      display: "none",
    },
  },
});

const HomeSeasonalProducts = () => {
  const { t } = useTranslation();
  const { searchState, setSearchState } = useContext(FilterContext);
  const history = useHistory();
  const classes = useStyles();
  const [isActive, setIsActive] = useState(0);
  const [currentCollection, setCurrentCollection] = useState({
    name: null,
    id: null,
    description: null,
  });

  const { data } = useQuery(GET_COLLECTION, {
    variables: {
      slug: "landing-page-1",
    },
  });

  useEffect(() => {
    setCurrentCollection({
      name: data?.collection?.name,
      description: data?.collection?.description,
      id: data?.collection?.id,
      __typename: "Collection",
    });
  }, [data]);

  let headerInfo = [];

  if (data && data?.collection) {
    headerInfo.push({
      name: data?.collection?.name,
      description: data?.collection?.description,
      id: data?.collection?.id,
    });
  }

  const children = data?.collection?.children;

  if (children?.length) {
    children?.map((c) => {
      headerInfo.push(c);
      return c;
    });
  }

  const toggleClass = (index, key) => {
    setIsActive(index);
    setCurrentCollection(key);
  };

  const onShowCollection = () => {
    // setSearchState({
    //   ...searchState,
    //   facetValueIds: [],
    //   collectionId: currentCollection,
    //   facetFaluesPerCollectionInit: true,
    // });
    history.push("/catalog");
  };

  const header = (
    <div className={classes.header}>
      {headerInfo?.map((key, i) => (
        <span
          className={`${isActive === i ? "active" : ""} ${classes.headerDivs}`}
          onClick={() => toggleClass(i, key)}
          key={i}
        >
          {/* {t(`home.${key}`)} */}
          {key.name}
        </span>
      ))}
    </div>
  );

  const description = (
    <div className={classes.description}>
      <span className={classes.title}>
        {t("home.seasonalProductsTitle")}
        {/* {currentCollection?.name} */}
      </span>
      <span className={classes.subTitle}>
        {t("home.seasonalProductsSubTitle")}
      </span>
      <button className={classes.button} onClick={onShowCollection}>
        {t("home.seasonalProductsButton")}
        {/* {currentCollection?.name} */}
      </button>
    </div>
  );

  const products = (
    <React.Fragment>
      <HomeProductsDesk
        collectionId={currentCollection?.id}
        cName={`${classes.products}`}
      />
      <HomeProductsRes
        collectionId={currentCollection?.id}
        cName={`${classes.productsRes}`}
      />
    </React.Fragment>
  );

  const productsContainer = (
    <div className={classes.productsContainer}>
      {description}
      {products}
    </div>
  );

  const buttonRes = (
    <button className={classes.buttonRes} onClick={onShowCollection}>
      {t("home.seasonalProductsButton")}
      {/* {currentCollection?.name} */}
    </button>
  );

  return (
    <React.Fragment>
      <div className={classes.main}>
        {header}
        {productsContainer}
        {buttonRes}
      </div>
    </React.Fragment>
  );
};

export default HomeSeasonalProducts;
