import { useMutation, useQuery } from "@apollo/client";
import { makeStyles, Snackbar, Modal, Fade, Backdrop } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/account.context";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import {
  ADD_TO_CART,
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
} from "../../../queries/cart.queries";
import {
  h4Medium,
  h6Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import Loader from "../../common/loader/loader.component";
import MuiAlert from "@material-ui/lab/Alert";

import "./account-orders.component.css";
import { useTranslation } from "react-i18next";
import AccountOrderRes from "../account-order-res/account-order-res.component";
import AccountDisplayOrder from "../account-display-order/account-display-order.component";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";
import { MOVE_STATE } from "../../../queries/checkout.queries";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: 40,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: 16,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    paddingBottom: 40,
  },
  empty: {
    ...h4Medium,
    opacity: 0.74,
    textAlign: "center",
  },

  headerTitles: {
    // marginRight: 48,
    ...h4Medium,
    opacity: 0.64,
    ...pointer,
  },

  tableHeaders: {
    ...h6Regular,
    opacity: 0.4,
  },

  tableTitles: {
    ...h6Regular,
    opacity: 0.4,
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const AccountOrders = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);
  const [itemToAdd, setItemToAdd] = useState({
    id: null,
    qty: 0,
  });

  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderCode, setOrderCode] = useState(null);
  const [index, setIndex] = useState(null);

  const { accountState, setAccountState } = useContext(AccountContext);

  const { loading, error, data } = useQuery(GET_ACTIVE_USER);

  const [addToCart, { loading: addToCardLoader }] = useMutation(ADD_TO_CART, {
    variables: {
      variantId: itemToAdd.id,
      qty: itemToAdd.qty,
    },
    refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setIsSnackOpen(true);
    },
  });

  const { data: activeOrder } = useQuery(GET_ACTIVE_ORDER);

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: async () => await addToCart(),
  });

  useEffect(() => {
    if (data) {
      setAccountState({
        ...accountState,
        isUser: !!data.activeCustomer,
        user: data.activeCustomer,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (!itemToAdd.id || itemToAdd.qty === 0) return;
    addItemToOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemToAdd]);

  const onAddLineToBasket = async (line) => {
    setItemToAdd({ id: line?.productVariant?.id, qty: line?.quantity });
  };

  const addItemToOrder = async () => {
    if (
      !activeOrder?.activeOrder ||
      activeOrder?.activeOrder?.state === "AddingItems"
    ) {
      await addToCart();
    }
    if (activeOrder?.activeOrder?.state === "ArrangingPayment") {
      await moveStateToAddingItems();
    }
  };

  if (loading) return <Loader color={"#B73232"} size={70} />;

  if (error) {
    console.log(error);
  }

  const onAddToBasket = (item) => {
    item?.lines?.forEach((line) => {
      setTimeout(async () => {
        setItemToAdd({
          id: line?.productVariant?.id,
          qty: line?.quantity,
        });
      }, 200);
    });
  };

  const onCloseSnack = (bool) => {
    setIsSnackOpen(bool);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableTitles = [
    `${t("account.Number")}`,
    `${t("account.Delivery date and time")}`,
    `${t("account.Amount")}`,
    `${t("account.Status")}`,
  ];
  const orders = data?.activeCustomer?.orders?.items;
  let arrayForSort = orders?.length ? [...orders] : [];

  arrayForSort = arrayForSort.reverse();
  arrayForSort = arrayForSort.filter((item) => item.state !== "AddingItems");

  return (
    <React.Fragment>
      <div className={classes.container}>
        <span className={`h2 ${classes.title}`}>{t("account.Orders")}</span>

        <div className="account-order-res">
          <AccountOrderRes orders={arrayForSort} />
        </div>
        <div className="account-order-reg">
          <div className={classes.content}>
            <div
              className={classes.header}
              style={{ width: "90%", margin: "0 auto", paddingTop: 40 }}
            >
              {[
                `${t("account.Confirmed")} ${arrayForSort?.length}`,
                // `${t("account.Recurring Delivery")} 0`,
              ].map((title, i) => (
                <span
                  key={i}
                  className={classes.headerTitles}
                  style={{ opacity: currentTab === i ? 1 : 0.64 }}
                  onClick={() => {
                    setCurrentTab(i);
                  }}
                >
                  {title}
                </span>
              ))}
            </div>

            {orders?.length ? (
              <table style={{ width: "90%", margin: "0 auto" }}>
                <tr>
                  {tableTitles.map((h, i) => (
                    <td key={i} className={classes.tableHeaders}>
                      {h}
                    </td>
                  ))}
                  <td></td>
                </tr>
                {arrayForSort.map((o, i) => {
                  return (
                    <tr key={i}>
                      <td
                        style={{
                          ...h6Regular,
                          opacity: 0.64,
                          ...pointer,
                        }}
                        onClick={async () => {
                          setOrderCode(o.code);
                          setOpen(true);
                        }}
                      >
                        {o.code}
                      </td>
                      <td style={{ ...h6Medium }}>
                        {o.createdAt.substring(0, 10)}
                      </td>
                      <td style={{ ...h6Medium }}>
                        ₪ {(o.totalWithTax / 100).toFixed(2)}
                      </td>
                      <td style={{ ...h6Medium }}>
                        <OrderTimeLine state={o?.state} />
                      </td>
                      <td
                        style={{
                          color: "#80BB34",
                          cursor: "pointer",
                          ...h6Medium,
                        }}
                        onClick={() => {
                          setIndex(i);
                          onAddToBasket(o);
                        }}
                      >
                        {addToCardLoader && i === index ? (
                          <Loader color={"#B73232"} size="20" />
                        ) : (
                          t("account.Add to basket")
                        )}
                      </td>
                    </tr>
                  );
                })}
              </table>
            ) : (
              <span className={classes.empty}>
                {t("account.No orders found")}
              </span>
            )}
          </div>
        </div>

        <Snackbar
          open={isSnackOpen}
          autoHideDuration={1000}
          onClose={() => onCloseSnack(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: `${t("css.snackBarPosition")}`,
          }}
        >
          <Alert onClose={() => onCloseSnack(false)} severity="success">
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>
                {t("itemPage.ItemEditMessage", {
                  action: `${t("itemPage.added")}`,
                })}
              </span>
            </div>
          </Alert>
        </Snackbar>
      </div>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <AccountDisplayOrder
            code={orderCode}
            handleClose={handleClose}
            onAddLineToBasket={onAddLineToBasket}
            onAddToBasket={onAddToBasket}
            addToCardLoader={addToCardLoader}
          />
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default AccountOrders;
