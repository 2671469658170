import { makeStyles } from "@material-ui/core";
import React from "react";
import { h6Medium } from "../../../shared/constants";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";

const useStyles = makeStyles({
  container: {
    borderRadius: 16,
    height: "auto",
    background: "#FFFFFF",
    marginBottom: 16,
    padding: 24,
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
});

const AccountOrderRes = ({ orders }) => {
  const classes = useStyles();

  return orders.length
    ? orders.map((o, i) => {
        return (
          <div className={classes.container} key={i}>
            <div className={classes.header}>
              <span style={{ ...h6Medium }}>
                {o.createdAt.substring(0, 10)}
              </span>
              <span style={{ ...h6Medium }}>
                ₪ {(o.totalWithTax / 100).toFixed(2)}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                ...h6Medium,
                marginBottom: 16,
                justifyContent: "center",
              }}
            >
              <OrderTimeLine state={o?.state} />
            </div>
            {/* <div style={{ borderTop: "1px dashed #15222F" }}>
              {JSON.stringify(orders[0].lines)}
            </div> */}
          </div>
        );
      })
    : "No Products Available";
};

export default AccountOrderRes;
