import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { h1, h2, h3, h5Regular } from "../../../shared/constants";
import { FilterContext } from "../../../context/filterContext";
const source = require("./header_hero.jpg").default;

const FirstImageSect = ({ src }) => {
  const { t } = useTranslation();
  const { setSearchState } = useContext(FilterContext);
  const dir = t("css.side");
  const history = useHistory();

  const imageSource = src || source;

  const useStyles = makeStyles({
    container: {
      position: "relative",
    },
    image: {
      maxWidth: 1680,
      width: "100%",
      height: 800,
      backgroundImage: `url(${imageSource}) `,
      backgroundSize: "cover",
      zIndex: "-100",
      backgroundPosition: "center",
      "@media (max-width:940px)": {
        height: 424,
      },
    },
    content: {
      position: "absolute",
      top: 260,
      left: dir === "rtl" ? 0 : 40,
      right: dir === "rtl" ? 40 : 0,
      maxWidth: 500,
      width: "100%",
      "@media (max-width:940px)": {
        top: 144,
        left: dir === "rtl" ? 0 : 16,
        right: dir === "rtl" ? 16 : 0,
      },
      "@media (max-width:370px)": {
        top: 144,
        left: dir === "rtl" ? 0 : 16,
        right: dir === "rtl" ? 16 : 0,
      },
    },
    title: {
      ...h1,
      display: "block",
      height: 90,
      color: "#FFFFFF",
      "@media (max-width:940px)": {
        ...h2,
        color: "#FFFFFF",
        height: 60,
        fontSize: "4em",
      },
      "@media (max-width:480px)": {
        ...h2,
        color: "#FFFFFF",
        height: 50,
        fontSize: "2em",
      },
      "@media (max-width:320px)": {
        ...h3,
        color: "#FFFFFF",
        height: 40,
      },
    },
    subTitle: {
      position: "absolute",
      marginLeft: dir === "rtl" ? 0 : 40,
      marginRight: dir === "rtl" ? 40 : 0,
      bottom: 260,
      ...h5Regular,
      maxWidth: 427,
      width: "100%",
      color: "#FFFFFF",
      opacity: 0.72,
      "@media (max-width:940px)": {
        maxWidth: 343,
        width: "100%",
        bottom: 56,
        marginLeft: 16,
      },
      "@media (max-width:370px)": {
        marginTop: 16,
        maxWidth: 343,
        width: "100%",
        bottom: 56,
      },
    },

    button: {
      position: "absolute",
      marginLeft: dir === "rtl" ? 0 : 40,
      marginRight: dir === "rtl" ? 40 : 0,
      bottom: 140,
      color: "#FFFFFF",
      width: 310,
      border: "none",
      cursor: "pointer",
      height: 56,
      fontSize: 19,
      background: "#80BB34",
      fontFamily: "Barlow, Assistant",
      fontWeight: 500,
      borderRadius: 16,
      color: "#FFFFFF",
      "@media (max-width:940px)": {
        maxWidth: 343,
        width: "100%",
        bottom: 56,
        marginLeft: 16,
      },
      "@media (max-width:370px)": {
        marginTop: 16,
        maxWidth: 343,
        width: "100%",
        bottom: 56,
      },
    },
  });

  const classes = useStyles();

  const navigateToCatlog = () => {
    setSearchState({
      isInit: true,
      facetFaluesPerCollectionInit: true,
      term: "",
      facetValuesDefaultFilters: [],
      facetFaluesDefaultPerCollection: [],
      facetValueIdsPerCollection: [],
      facetValueIds: [],
      collectionId: null,
    });
    history.push("/catalog");
  };

  return (
    <div className={classes.container}>
      <div className={classes.image}></div>
      <div className={classes.content} style={{ t }}>
        <span className={`${classes.title}`}>{t("home.imageTitleOne")}</span>
        <span className={`${classes.title}`}>{t("home.imageTitleTwo")}</span>
      </div>
      <span className={`${classes.subTitle}`}>{t("home.imageSubTitle")}</span>
      <button className={`${classes.button}`} onClick={navigateToCatlog}>{t("home.startShopping")}</button>
    </div>
  );
};

export default FirstImageSect;
