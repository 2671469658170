import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { h4Medium, h5Medium, pointer } from "../../../shared/constants";
import { useTranslation } from "react-i18next";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import SearchLocationInput from "../places-autocomplete/places-autocomplete.component";
import { AccountContext } from "../../../context/account.context";
import { useMutation } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";
import { ErrorHandlingContext } from "../../../context/error-handling.context";
import Loader from "../loader/loader.component";
import { VALIDATE_SHIPPING_ADDRESS } from "../../../mutations/validate-shipping-address.mutation";

const useStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    width: 640,
    height: "auto",
    padding: 48,
    borderRadius: 32,

    "@media (max-width:940px)": {
      width: 340,
    },
  },

  header: {
    display: "flex",
    justifyContent: "space-between",
    ...h4Medium,
    marginBottom: 32,

    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },

  closeIcon: {
    width: 24,
    height: 24,
    cursor: pointer,

    "@media (max-width:940px)": {
      width: 16,
      height: 16,
    },
  },
});

const DeliveryAddress = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { accountState, setAccountState } = useContext(AccountContext);
  const { setErrorHandlingState } = useContext(ErrorHandlingContext);

  const { isAddress } = accountState;

  const [isVaildInput, setIsVaildInput] = useState(false);
  const [addressValues, setAddressValues] = useState({
    streetLine1: isAddress,
  });

  let [
    setShippingAddress,
    { loading: setOrderShippingLoader, error: setOrderShippingError },
  ] = useMutation(VALIDATE_SHIPPING_ADDRESS, {
    variables: {
      streetLine1: addressValues?.streetLine1,
      city: addressValues?.city,
      countryCode: "IL",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      if (data?.validateShippingAddress?.message) {
        setErrorHandlingState({
          showError: true,
          data: data?.validateShippingAddress?.message,
        });
        return;
      }
      setAccountState({
        ...accountState,
        isAddress: addressValues.streetLine1,
        isDeliveryModalAddress: false,
      });
      localStorage.setItem("isAddressSet", true);
    },
  });

  const onSetShippingOrder = async () => {
    try {
      await setShippingAddress();
    } catch (err) {
      setErrorHandlingState({
        showError: true,
        data: "An internal error has occurred",
      });
    }
  };

  React.useEffect(() => {
    if (setOrderShippingError?.message) {
      setErrorHandlingState({
        showError: true,
        data: setOrderShippingError?.message,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOrderShippingError]);

  return (
    <div className={classes.container}>
      <div className={classes.header} style={{ direction: t("css.side") }}>
        <span>{t("header.deliveryAddress")}</span>
        <span
          style={{ ...pointer }}
          onClick={() => {
            setAccountState({
              ...accountState,
              isDeliveryModalAddress: false,
            });
          }}
        >
          <RemoveIcon className={classes.closeIcon} />
        </span>
      </div>

      <div style={{ mrginRight: 20 }}>
        <SearchLocationInput
          styles={{
            margin: "32px 0px 10px 0px",
          }}
          setAddressValues={setAddressValues}
          setIsVaildInput={setIsVaildInput}
          value={addressValues?.streetLine1}
          showMapAbove={false}
        />
      </div>
      <button
        style={{
          maxWidth: 320,
          width: "100%",
          height: "56px",
          background:
            addressValues.streetLine1 === null ? "lightGrey" : "#80BB34",
          color: "#ffffff",
          borderRadius: 16,
          border: "none",
          ...pointer,
          ...h5Medium,
          marginTop: 24,
        }}
        onClick={() => {
          if (addressValues.streetLine1 === "") return;
          if (isVaildInput && addressValues.streetLine1 !== "") {
            onSetShippingOrder();
          }
        }}
      >
        {setOrderShippingLoader ? (
          <Loader color={"#B73232"} size={30} />
        ) : (
          t("header.startShoping")
        )}
      </button>
    </div>
  );
};

export default DeliveryAddress;
