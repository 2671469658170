import { makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { h6Regular } from "../../../shared/constants";
import CartActions from "../cart-actions/cart-actions.component";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    borderTop: "1px dashed #15222F1A",
    marginTop: 16,
    justifyContent: "space-between",
    alignItems: "center",

    "@media (max-width:940px)": {
      flexDirection: "column",
    },
  },

  description: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    alignContent: "center",
    width: 288,
    marginTop: 16,

    "@media (max-width:940px)": {
      width: "100%",
    },
  },

  imageContainer: {
    width: 88,
    height: 88,
    borderRadius: 8,
    marginRight: 24,

    "@media (max-width:940px)": {
      width: 56,
      height: 56,
      marginRight: 16,
    },
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 8,
  },

  name: {
    ...h6Regular,
    opacity: 0.64,
  },

  actions: {
    width: 280,

    "@media (max-width:940px)": {
      width: "100%",
      marginTop: 8,
    },
  },
});

const CartItem = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container} style={{ direction: t("css.side") }}>
      <div className={classes.description}>
        <div className={classes.imageContainer}>
          <img
            src={item?.featuredAsset?.preview}
            alt={item?.featuredAsset?.name}
            className={classes.image}
          />
        </div>
        <span className={classes.name}>{item?.productVariant?.name}</span>
      </div>
      <div className={classes.actions}>
        <CartActions item={item} />
      </div>
    </div>
  );
};

export default CartItem;
