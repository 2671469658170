import React, { useContext } from "react";
import { FilterContext } from "../../../context/filterContext";
import Checkbox from "../../common/checkbox/checkbox";

const CatalogSubFilters = ({
  filters,
  updateFacetsValues,
  removeFacetsValues,
}) => {
  const { searchState } = useContext(FilterContext);

  const { facetValueIds } = searchState;

  const filetrsKeys = Object.keys(filters);

  return (
    <div>
      {filetrsKeys.map((key, i) => (
        <div className="catalog-filters__spotlight" key={i}>
          <div className="h6-medium">{key}</div>
          <div className="catalog-filters__spotlight__checkboxes">
            {filters[key].map((f) => {
              let isChecked = false;
              if (facetValueIds.some((x) => x.id === f.facetValue.id)) {
                isChecked = true;
              }

              const { name, id } = f.facetValue;

              return (
                <Checkbox
                  updateFacetsValues={updateFacetsValues}
                  removeFacetsValues={removeFacetsValues}
                  value={name}
                  facetValue={f.facetValue}
                  isChecked={isChecked}
                  key={id}
                />
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CatalogSubFilters;
