import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import Slider from "react-slick";
import { settings } from './item-page-also-like.helper'

import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";

import ItemCard from "../../common/item-card/item-card";

import "./item-page-also-like.component.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useTranslation } from "react-i18next";

export const ItemPageAlsoLike = ({ data }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [collectionIds, setCollectionIds] = useState([]);

  const [loadLookAlikeItems, { called, loading, data: lookAlikeItems }] =
    useLazyQuery(GET_ITEMS_WITH_FILTERS, {
      variables: { collectionId: collectionIds },
    });

  useEffect(() => {
    const collectionsData = data?.product?.collections?.map((col) => col?.id);
    if (collectionsData?.length) {
      setCollectionIds(collectionsData[0]);
      loadLookAlikeItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  if (called && loading) return <p>Loading ...</p>;

  // const newArray = [];
  // let tempArray = [];

  // lookAlikeItems?.search?.items?.map((s) => {
  //   tempArray.push(s);
  //   if (tempArray.length === 2) {
  //     newArray.push(tempArray);
  //     tempArray = [];
  //   }
  //   return s;
  // });

  const showItem = (x) => {
    history.push(`/item/${x.slug}`);
  };



  return (
    <div className="item-details__look-alike">
      {lookAlikeItems?.search?.items?.length ? (
        <React.Fragment>
          <h3 className="item-details__look-alike--item h3">
            {t("itemPage.You may also like")}
          </h3>
          <Slider {...settings}>
            {lookAlikeItems?.search?.items?.map((item) => (
              <div key={item?.productVariantId} style={{ maxHeight: 150, width: 150 }}>
                <ItemCard
                  itemCard={item}
                  id={item?.productVariantId}
                  productVariantName={item?.productVariantName}
                  title={item?.merchantName}
                  img={item?.productAsset?.preview}
                  description={item?.productName}
                  weight={item?.customFields?.priceMeasurement}
                  price={(item?.price?.value)}
                  merchantLogo={item?.merchantAsset?.preview}
                  showItem={() => showItem(item)}
                />
              </div>
            ))}
          </Slider>
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
};

export default ItemPageAlsoLike;
