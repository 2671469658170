import { makeStyles } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import React from "react";
import { useTranslation } from "react-i18next";
import { h2, h3 } from "../../../shared/constants";
import { GET_MERCHANT_LOGOS } from "../../../queries/queries";
import BrokenImageIcon from '../item-card/broken-image';

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    maxWidth: 900,
    margin: " 0 auto",
    textAlign: "center",
    "@media (max-width:940px)": {
      maxWidth: "100%",
    },
  },
  title: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    marginBottom: 80,
    ...h2,
    fontSize: 58,
    "@media (max-width:940px)": {
      ...h3,
      height: 96,
      marginBottom: 40,
    },
  },
  imagesContainer: {
    maxWidth: 900,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    gap: 10,
  },
  imageContainer: {
    width: 120,
    height: 80,
    display: "flex",
    flexBasis: 120,
    flexShrink: 0,
    flexGrow: 0,
    "@media (max-width:940px)": {
      marginBottom: 24,
    },
  },
  image: {
    objectFit: "contain",
  }
});

const OurSuppliers = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data } = useQuery(GET_MERCHANT_LOGOS);

  const logos = data?.search?.items.filter(
    (merchant, index, self) =>
      index ===
      self.findIndex((m) => m?.merchantAsset?.id === merchant?.merchantAsset?.id)
  );

  return (
    <div className={classes.container}>
      <span className={classes.title}>{t("common.Our Suppliers")}</span>
      <div className={classes.imagesContainer}>
        {logos?.map((m, i) => {
          const imageContainer =
            m?.merchantAsset?.preview ?
              <div className={classes.imageContainer} key={i}>
                <img
                  className={classes.image}
                  src={m?.merchantAsset?.preview}
                  alt={"logo_img"}
                  style={{ width: "100%", height: "100%" }}
                ></img>
              </div> :
              <div className={classes.imagesContainer}>
                <BrokenImageIcon />
              </div>

          return imageContainer;
        })}
      </div>
    </div>
  );
};

export default OurSuppliers;
