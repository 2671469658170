import React from "react";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { GET_COLLECTIONS } from "../../../queries/queries";
import { useQuery } from "@apollo/client";
import BrokenImageIcon from "../../common/item-card/broken-image";

import { ReactComponent as ArrowIcon } from "../../../assets/upArrow.svg";
import "./categories-container.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const CategoriesContainer = ({ isOpen, setIsOpen, updateFilters }) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const onItemClick = (e) => {
    updateFilters(e);
  };
  const { loading, error, data } = useQuery(GET_COLLECTIONS);
  const handleClose = () => {setIsOpen(false)};

  React.useEffect(() => {
    if (data?.collections?.items) {
      let categories = data?.collections?.items?.filter(
        (c) => c?.name !== "Categories"
      );
      categories = data?.collections?.items?.filter(
        (c) => c?.parent?.slug === "categories"
      );
      setCategories(categories);
    }
  }, [data]);

  React.useEffect(() => {
    document.addEventListener("keydown", handleClose, false);

    return () => {
      document.removeEventListener("keydown", handleClose, false);
    };
  }, [handleClose]);

  if (loading) return "";
  if (error) return `Error! ${error.message}`;

  return (
    <React.Fragment>
      {isOpen ? (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={`CC ${t("css.categoriesBoxStyle")}`}>
            <div className="CC__title">
              <span>{t("header.categories")}</span>
              <ArrowIcon
                onClick={handleClose}
              />
            </div>
            <div className="CC__body">
              {categories?.map((col) => {
                const image = col?.featuredAsset?.preview ? (
                  <img
                    src={col?.featuredAsset?.preview}
                    alt={"image_container"}
                  ></img>
                ) : (
                  <BrokenImageIcon style={{ width: "100%", height: "100%" }} />
                );

                return (
                  <div
                    className="CC__body-item"
                    key={col.id}
                    onClick={() => {
                      onItemClick(col);
                    }}
                  >
                    <div className="CC__item-img-container">{image}</div>
                    <span className="h6-regular CC__body-item-text">
                      {col.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div></ClickAwayListener>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default CategoriesContainer;
