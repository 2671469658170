import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { Fade } from "@material-ui/core";

import { GET_ACTIVE_USER, LOGOUT } from "../../../queries/auth.queries";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as CategoriesIcon } from "../../../assets/catalog.svg";
import { ReactComponent as BasketIcon } from "../../../assets/basket.svg";
import { ReactComponent as HelpIcon } from "../../../assets/help.svg";
import { ReactComponent as OrdersIcon } from "../../../assets/orders.svg";
import { ReactComponent as TruckIcon } from "../../../assets/truck.svg";
import { ReactComponent as AddressIcon } from "../../../assets/address.svg";
// import { ReactComponent as BankIcon } from "../../../assets/creditCard.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/settings.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/globe.svg";
import { ReactComponent as ExitIcon } from "../../../assets/exit.svg";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { GET_TOTAL_LINES } from "../../../queries/cart.queries";
import HeaderAccount from "../header-account/header-account.component";
// import configData from "../../../config/config.json";
import { AccountContext } from "../../../context/account.context";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  listItem: {
    color: "#15222F",
    opacity: 0.56,
  },
  removeIcon: {
    height: 24,
    width: 24,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

const iconsContainer = {
  Categories: <CategoriesIcon />,
  // Basket: <BasketIcon />,
  // Help: <HelpIcon />,
  Orders: <OrdersIcon />,
  "Recurring Delivery": <TruckIcon />,
  Addresses: <AddressIcon />,
  // "Bank Cards": <BankIcon />,
  Settings: <SettingsIcon />,
};

const MenuDrawer = ({ toggleDrawer }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(false);
  const { accountState } = useContext(AccountContext);
  const classes = useStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (lang) {
      i18n.changeLanguage("he");
      localStorage.setItem("lang", "he");
    } else {
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  const [isActiveUser, setActiveUser] = useState(false);
  const [open, setOpen] = useState(false);

  const { data } = useQuery(GET_ACTIVE_USER, {
    refetchQueries: [{ query: GET_TOTAL_LINES }],
  });

  useEffect(() => {
    if (data && data.activeCustomer) {
      setActiveUser(true);
    }
  }, [data]);

  const navigateTo = (page) => {
    if (page === "Categories") {
      history.push("/catalog");
      return;
    }

    if (page === "Basket" || page === "Help") return;
    history.push({
      pathname: "/account",
      state: {
        // location state
        page,
      },
    });
  };

  const onAuth = () => {
    if (isActiveUser) {
      onLogout();
    } else {
      toggleDrawer(lang ? "right" : "left", false);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [logout] = useMutation(LOGOUT, {
    refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_USER }],
  });

  const onLogout = async () => {
    await logout();
    // console.log(document.getElementById('engt'))
    document.getElementById('engt').remove();
    localStorage.removeItem("isAddressSet");
    // history.push("/");
    // window.location.reload();
  };

  // should be 4 pages
  // const pages = ["Orders", "Addresses", "Bank Cards", "Settings"];
  const pages = ["Orders", "Addresses", "Settings"];

  return (
    <div className={classes.list}>
      <List
        onClick={toggleDrawer(lang ? "right" : "left", false)}
        onKeyDown={toggleDrawer(lang ? "right" : "left", false)}
      >
        <ListItem button key={"logo"}>
          <ListItemIcon>
            <RemoveIcon className={classes.removeIcon} />
          </ListItemIcon>
          <span
            className="h4-mobile"
            onClick={() => {
              history.push("/");
            }}
          >
            {accountState?.sharedData?.TITLE}
          </span>
        </ListItem>
      </List>
      <List
        onClick={toggleDrawer(lang ? "right" : "left", false)}
        onKeyDown={toggleDrawer(lang ? "right" : "left", false)}
      >
        {/* {["Categories", "Basket", "Help"].map((text, index) => ( */}
        {isActiveUser &&
          ["Categories"].map((text, index) => (
            <ListItem
              button
              key={text}
              onClick={() => {
                navigateTo(text);
              }}
            >
              <ListItemIcon>{iconsContainer[text]}</ListItemIcon>
              <ListItemText
                primary={t(`menu.${text}`)}
                className={classes.listItem}
              />
            </ListItem>
          ))}
      </List>
      <List
        onClick={toggleDrawer(lang ? "right" : "left", false)}
        onKeyDown={toggleDrawer(lang ? "right" : "left", false)}
      >
        {isActiveUser &&
          [
            `${t("account.Orders")}`,
            `${t("account.Addresses")}`,
            // `${t("account.BankCards")}`,
            `${t("account.Settings")}`,
          ].map((page, i) => (
            <ListItem
              button
              key={page}
              onClick={() => {
                history.push({
                  pathname: "/account",
                  state: {
                    page: pages[i],
                  },
                });
              }}
            >
              <ListItemIcon>{iconsContainer[pages[i]]}</ListItemIcon>
              <ListItemText primary={page} className={classes.listItem} />
            </ListItem>
          ))}
      </List>
      <List
        onClick={toggleDrawer(lang ? "right" : "left", false)}
        onKeyDown={toggleDrawer(lang ? "right" : "left", false)}
      >
        <ListItem button key={"language"} onClick={() => setLang(!lang)}>
          <ListItemIcon>
            <GlobeIcon />
          </ListItemIcon>
          <ListItemText
            primary={t(`menu.language`)}
            className={classes.listItem}
          />
        </ListItem>
      </List>
      <List
        onClick={() => {
          onAuth();
        }}
      >
        <ListItem button key={"SignOut"}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText
            primary={isActiveUser ? t(`menu.Sign Out`) : t("auth.sign in")}
            className={classes.listItem}
          />
        </ListItem>
      </List>

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <HeaderAccount handleClose={handleClose} />
        </Fade>
      </Modal>
    </div>
  );
};

export default MenuDrawer;
