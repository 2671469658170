import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateCustomer(
    $title: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
  ) {
    updateCustomer(
      input: {
        title: $title
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
      }
    ) {
      id
      title
      firstName
      lastName
      phoneNumber
      emailAddress
    }
  }
`;

const UPDATE_ADDRESS = gql`
  mutation updateCustomerAddress($id: ID!, $defaultShippingAddress: Boolean) {
    updateCustomerAddress(
      input: { id: $id, defaultShippingAddress: $defaultShippingAddress }
    ) {
      __typename
      id
    }
  }
`;

const UPDATE_FULL_ADDRESS = gql`
  mutation UpdateAddress(
    $city: String
    $fullName: String
    $id: ID!
    $phoneNumber: String
    $postalCode: String
    $streetLine1: String
  ) {
    updateCustomerAddress(
      input: {
        city: $city
        fullName: $fullName
        id: $id
        phoneNumber: $phoneNumber
        postalCode: $postalCode
        streetLine1: $streetLine1
      }
    ) {
      ...Address
      __typename
    }
  }
  fragment Address on Address {
    id
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country {
      id
      code
      name
      __typename
    }
    phoneNumber
    defaultShippingAddress
    defaultBillingAddress
    __typename
  }
`;

const FETCH_LANGUAGE = gql`
  query {
    activeCustomer {
      languageCode
    }
  }
`;
export { UPDATE_USER, UPDATE_ADDRESS, UPDATE_FULL_ADDRESS, FETCH_LANGUAGE };
