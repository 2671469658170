import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GET_MERCHANT_LOGOS } from "../../../queries/queries";

import "./item-page-merchant-and-title.component.css";

const ItemPageMerchantAndTitle = ({ data }) => {
  const { data: merchantsData } = useQuery(GET_MERCHANT_LOGOS);
  const [merchantSlug, setMerchantSlug] = useState(null);

  React.useEffect(() => {
    if (merchantsData) {
      const merchant = merchantsData?.search?.items?.filter((item) => {
        return item?.productId === data?.product?.id;
      });
      setMerchantSlug(merchant[0]?.merchantSlug);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantsData]);
  return (
    <React.Fragment>
      <div className="IPMAT__image-section">
        <div className="IPMAT__merchant-img">
          <img
            src={data?.product?.merchant?.featuredAsset?.source}
            alt={data?.product?.merchant?.name}
          ></img>
        </div>
        <Link
          to={{ pathname: `/supplier/${merchantSlug}`, replace: true }}
          className="IPMAT__supplier h7-regular"
        >
          {data?.product?.merchant?.name}
        </Link>
      </div>
      <span className="IPMAT__title h2">{data?.product?.name}</span>
    </React.Fragment>
  );
};

export default ItemPageMerchantAndTitle;
