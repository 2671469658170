import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core";

import {
  h5Medium,
  pointer,
  h3,
  h4Medium,
  h4Light,
  h5Light,
} from "../../../shared/constants";
import { ReactComponent as DeliveryTruckIcon } from "../../../assets/deliveryTruck.svg";
import { useTranslation } from "react-i18next";
import AuthLogin from "../../auth/auth-login.component";
import AuthRegister from "../../auth/auth-register/auth-register.component";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

const useStyles = makeStyles({
  bodyAddresses: {
    overflow: "auto",
  },

  addressDetails: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
  },

  addressDetailsBody: {
    display: "flex",
    flexDirection: "column",
  },

  bodyTitles: {
    display: "flex",
    marginBottom: 32,

    "@media (max-width:940px)": {
      marginBottom: 21,
    },
  },

  button: {
    height: 56,
    width: 240,
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },

  container: {
    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    width: 416,
    height: 524,
    padding: 48,
    overflow: "auto",
  },
  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: 200,

    "@media (max-width:940px)": {
      width: 180,
    },
  },

  headerLogin: {
    ...h4Medium,
    ...pointer,

    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },
  headerRegister: {
    ...h4Light,
    ...pointer,

    "@media (max-width:940px)": {
      ...h5Light,
    },
  },
  notClickedTitle: {
    opacity: 0.64,
  },
});

const LoginProcess = ({ setProcesses, processes }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: userData } = useQuery(GET_ACTIVE_USER);

  const [closeAccordion, setCloseAccordion] = useState(true);
  const [isLoginMode, setIsLoginMode] = useState(true);

  useEffect(() => {
    if (userData && userData.activeCustomer) {
      setProcesses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const header = (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span
          className={`${classes.headerLogin} ${
            !isLoginMode ? classes.notClickedTitle : ""
          }`}
          onClick={() => {
            setIsLoginMode(true);
          }}
        >
          {t("auth.sign in")} / {t("auth.register")}
        </span>
      </div>
    </div>
  );

  if (userData && userData.activeCustomer) {
    return "";
  }
  return (
    <Accordion
      style={{
        borderRadius: "16px",
        padding: 30,
        boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
        marginBottom: 24,
        borderBottom: "none",
      }}
      expanded={closeAccordion}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => {
          setCloseAccordion(!closeAccordion);
        }}
      >
        <Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DeliveryTruckIcon style={{ marginRight: 16 }} />
            <span style={{ ...h3 }}>{t("auth.Login")}</span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          {header}
          <div
            style={{
              maxWidth: 400,
            }}
          >
            <AuthRegister handleClose={() => {}} route="/checkout" />
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default LoginProcess;
