import React, { useState } from "react";
import AddPhone from "../add-phone/add-phone.component";
import VerifyNumber from "./verifyNumber.component";

const VerifyNumberContainer = ({ handleClose, onVerify, phoneNumber, setPhoneValue }) => {
  const [addPhoneScreen, setAddPhoneScreen] = useState(false);

  const onChangeNumber = (bool) => {
    setAddPhoneScreen(bool);
  };

  return (
    <React.Fragment>
      {addPhoneScreen ? (
        <AddPhone
          onChangeNumber={onChangeNumber}
          handleClose={handleClose}
          phoneValue={phoneNumber}
          setPhoneValue={setPhoneValue}
        />
      ) : (
        <VerifyNumber
          onChangeNumber={onChangeNumber}
          handleClose={handleClose}
          onVerify={onVerify}
          phoneNumber={phoneNumber}
        />
      )}
    </React.Fragment>
  );
};

export default VerifyNumberContainer;
