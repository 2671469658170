import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useQuery } from "@apollo/client";
import { Redirect, useHistory, useLocation } from "react-router-dom";

import { GET_PRODUCT } from "../../queries/queries";

import "./item-page.css";

// import { ReactComponent as HeartIcon } from "../../assets/heart.svg";
import { ReactComponent as VariantsIcon } from "../../assets/cards.svg";
import { ReactComponent as ArrowIcon } from "../../assets/arrow.svg";

import ItemPageImageGalery from "./item-page-image-galery/item-page-image-galery.component";
import ItemPageMerchantAndTitle from "./item-page-merchant-and-title/item-page-merchant-and-title.component";
import ItemPageAddButton from "./item-page-add-button/item-page-add-button.component";
import ItemPageItemDetails from "./item-page-item-details/item-page-item-details.component";
import ItemPageResponsive from "./item-page-responsive/item-page-responsive.component";
import { ItemPageAlsoLike } from "./item-page-also-like/item-page-also-like.component";
import Breadcrumbs from "../common/breadcrumbs/breadcrumbs.component";
import { ReactComponent as BreadCrumbsArrow } from "../../assets/rightArrow.svg";
import { ReactComponent as BreadCrumbsArrowLeft } from "../../assets/leftArrow.svg";
import Loader from "../common/loader/loader.component";
import { useTranslation } from "react-i18next";
import { h5Medium, h7Medium, pointer } from "../../shared/constants";

function Item(props) {
  window.scrollTo(0, 0);
  const history = useHistory();
  const location = useLocation();
  const locationPath = location?.pathname?.split("/");
  const productSlug = locationPath[locationPath.length - 1];
  const { t } = useTranslation();
  const dir = t("css.side");

  const { loading, error, data } = useQuery(GET_PRODUCT, {
    variables: { slug: productSlug },
  });

  const [currentVariant, setCurrentVariant] = useState(null);
  const [variantsArray, setVariantsArray] = useState([]);

  useEffect(() => {
    if (data) {
      setVariantsArray(data.product?.variants);
      setCurrentVariant(data.product?.variants[0]);
    }
  }, [data]);

  if (!productSlug) return <Redirect to="/catalog" />;

  if (error) return `Error! ${error.message}`;

  if (data?.product === null) return <Redirect to="/catalog" />;

  const onGoBack = () => {
    history.push("/catalog");
  };

  return (
    <React.Fragment>
      <div style={{ paddingTop: 120 }}></div>
      <div style={{ display: "block" }}>
        <Breadcrumbs prevPage={t("itemPage.catalog")} onGoBack={onGoBack}>
          {dir === "ltr" ? (
            <BreadCrumbsArrow style={{ margin: "0 16px 0 16px" }} />
          ) : (
            <BreadCrumbsArrowLeft style={{ margin: "0 16px 0 16px" }} />
          )}
          <span className="h6-regular">{data?.product?.name}</span>
        </Breadcrumbs>
      </div>
      <ItemPageResponsive data={data} currentVariant={currentVariant} />

      {loading ? (
        <Loader color={"#B73232"} size={50} />
      ) : (
        <div className="item">
          <ItemPageImageGalery
            data={data}
            currentVariant={currentVariant || data?.product?.variants[0]}
            onVariantChange={setCurrentVariant}
          />
          <div className="item__details">
            <div className="item__details__info">
              <ItemPageMerchantAndTitle data={data} />

              {variantsArray?.length > 1 ? (
                <div
                  style={{
                    position: "relative",
                    marginTop: 16,
                    marginBottom: 8,
                  }}
                >
                  <span className={clsx("h5-medium", "item__variants-title")}>
                    {t("itemPage.Options")}:
                  </span>
                  <div className="item__variants">
                    {variantsArray.map((v) => (
                      <div
                        key={v.id}
                        onClick={() => {
                          setCurrentVariant(v);
                        }}
                        className={clsx({
                          "item__variants-variant": true,
                          selected: v?.id === currentVariant?.id,
                        })}
                      >
                        <span className="item__variants-variant-img">
                          <img alt="" src={v?.featuredAsset?.preview} />
                        </span>
                        <div className="item__variants-variant-info">
                          <span className="item__variants-variant-name">
                            {v?.name}
                          </span>
                          <span className="item__variants-variant-price">
                            ₪{(v?.priceWithTax / 100).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* <span className="item__details__info__weight h5-regular">
              per 1 lb
            </span> */}
              <div className="item__details__info__actions">
                <span className="item__details__info__actions--price h4-medium">
                  ₪ {(currentVariant?.priceWithTax / 100).toFixed(2)}
                </span>
                <ItemPageAddButton
                  item={currentVariant}
                  preview={data?.product?.assets[0]?.source}
                />
                {/* <span className="item__details__info__actions--reaction">
                <HeartIcon />
              </span> */}
              </div>
            </div>
            <ItemPageItemDetails data={data} />
            <ItemPageAlsoLike data={data} />
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Item;
