import React from "react";

import "./item-page-responsive.component.css";

import ItemPageMerchantAndTitle from "../item-page-merchant-and-title/item-page-merchant-and-title.component";
import ItemPageImageGalery from "../item-page-image-galery/item-page-image-galery.component";
import { ReactComponent as HeartIcon } from "../../../assets/heart.svg";
import ItemPageItemDetails from "../item-page-item-details/item-page-item-details.component";
import { ItemPageAlsoLike } from "../item-page-also-like/item-page-also-like.component";
import ItemPageAddButton from "../item-page-add-button/item-page-add-button.component";

function ItemPageResponsive({ data, currentVariant }) {
  return (
    <div className="IPR">
      <ItemPageMerchantAndTitle data={data} className="IPR__merchant" />
      <ItemPageImageGalery data={data} />
      <div className="IPR__price-and-heart">
        <div>
          <span className="item__details__info__actions--price h4-medium">
            ₪ {(data?.product?.variants[0]?.priceWithTax / 100).toFixed(2)}
          </span>
          {/* <span className="item__details__info__weight h5-regular">
            per 1 lb
          </span> */}
        </div>
        {/* <div>
          <span className="IPR__heart-icon">
            <HeartIcon />
          </span>
        </div> */}
      </div>
      <ItemPageAddButton
        item={currentVariant}
        preview={data?.product?.assets[0]?.source}
      />
      <ItemPageItemDetails data={data} />
      <div className="IPR__cross-line"></div>
      <ItemPageAlsoLike data={data} />
    </div>
  );
}

export default ItemPageResponsive;
