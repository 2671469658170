import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import AuthLogin from "../../auth/auth-login.component";
import { useTranslation } from "react-i18next";
import {
  h4Light,
  h4Medium,
  h5Light,
  h5Medium,
  pointer,
} from "../../../shared/constants";
import AuthRegister from "../../auth/auth-register/auth-register.component";

const useStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    boxShadow: "0px 16px 32px rgba(29, 38, 102, 0.08)",
    borderRadius: 32,
    width: 416,
    height: 524,
    padding: 48,
    overflow: "auto",

    "@media (max-width:940px)": {
      width: "auto",
      height: "auto",
    },
  },
  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: 200,

    "@media (max-width:940px)": {
      width: 180,
    },
  },

  headerLogin: {
    ...h4Medium,
    ...pointer,

    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },
  headerRegister: {
    ...h4Light,
    ...pointer,

    "@media (max-width:940px)": {
      ...h5Light,
    },
  },
  notClickedTitle: {
    opacity: 0.64,
  },

  closeIcon: {
    width: 32,
    height: 32,
    cursor: pointer,

    "@media (max-width:940px)": {
      width: 16,
      height: 16,
    },
  },
});

const HeaderAccount = ({ handleClose }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [isLoginMode, setIsLoginMode] = useState(true);

  const header = (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span
          className={`${classes.headerLogin} ${
            !isLoginMode ? classes.notClickedTitle : ""
          }`}
          onClick={() => {
            setIsLoginMode(true);
          }}
        >
          {t("auth.sign in")}
        </span>
        <span
          className={`${classes.headerRegister} ${
            isLoginMode ? classes.notClickedTitle : ""
          }`}
          onClick={() => {
            setIsLoginMode(false);
          }}
        >
          {t("auth.register")}
        </span>
      </div>

      <div className="pointer" onClick={handleClose}>
        <RemoveIcon className={classes.closeIcon} />
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      {header}
      {isLoginMode ? (
        <AuthLogin handleClose={handleClose} />
      ) : (
        <AuthRegister handleClose={handleClose} />
      )}
    </div>
  );
};

export default HeaderAccount;
