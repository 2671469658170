import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";

import "./filter-tag.component.css";

const FilterTag = ({ value, removeFacetsValues, typeName, id }) => {
  const { t } = useTranslation();
  const dir = t("css.side");

  const onRemove = () => {
    removeFacetsValues(typeName, id);
  };

  return value && typeName !== "search" ? (
    <div
      className="filter-tag"
      style={{
        margin: dir === "ltr" ? "0px 0px 7px 15px" : "0px 15px 7px 0px",
      }}
    >
      <span className="h6-regular filter-tag__value">
        {value?.name || value}
      </span>
      <RemoveIcon
        className="filter-tag__remove"
        onClick={onRemove}
        style={{
          margin: dir === "ltr" ? "0px 0px 0px 7px" : "0px 7px 0px 0px",
        }}
      />
    </div>
  ) : (
    ""
  );
};

export default FilterTag;
