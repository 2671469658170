export const settings = {
    speed: 500,
    slidesToShow: 2,
    responsive: [
        {
            breakpoint: 940,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 370,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
};