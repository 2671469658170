import { useMutation, useQuery } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  CHANGE_EMAIL_ADDRESS,
  GET_ACTIVE_USER,
  UPDATE_PASSWORD,
} from "../../../queries/auth.queries";
import {
  h3,
  h5Medium,
  h6Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import FormInput from "../../common/form-input/form-input.component";
import Loader from "../../common/loader/loader.component";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import PreferredLanguage from "../../../shared/components/preferred-language/preferred-language.component";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    marginBottom: 40,
  },
  security: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: 16,
    width: "auto",
    height: 500,
    marginBottom: 24,
    padding: 40,
  },
  personalInfo: {
    display: "flex",
    flexDirection: "column",
    background: "#FFFFFF",
    borderRadius: 16,
    width: "auto",
    height: 380,
    padding: 40,
  },
  innerTitles: {
    ...h3,
    marginBottom: 40,
  },
  editParams: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 500,
    ...h6Regular,

    "@media (max-width:940px)": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      width: "100%",
    },
  },
  formInput: {
    width: "70%",

    "@media (max-width:940px)": {
      width: "auto",
    },
  },
  authenticationDiv: {
    ...h5Medium,
  },
  bdayDiv: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #15222F",
    opacity: 0.8,
    textAlign: "center",
    ...h6Regular,
  },
  change: {
    ...h6Medium,
    ...pointer,
    color: "#80BB34",
  },
});

const AccountSettings = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [message, setMessage] = useState("");

  const { loading, error, data } = useQuery(GET_ACTIVE_USER);

  const [activeUser, setActiveUser] = useState({
    emailAddress: "",
    password: "",
    name: "",
    oldPassword: "",
    newPassword: "",
  });
  const [currentPass, setCurrentPass] = useState("");
  const { firstName, lastName, oldPassword, newPassword, emailAddress } =
    activeUser;

  let [updatePass, { loading: updatePassLoader }] = useMutation(
    UPDATE_PASSWORD,
    {
      variables: {
        old: oldPassword,
        new: newPassword,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
      awaitRefetchQueries: true,
    }
  );

  let [changeEmailAddress, { loading: changeEmailAddressLoader }] = useMutation(
    CHANGE_EMAIL_ADDRESS,
    {
      variables: {
        password: currentPass,
        emailAddress: emailAddress,
      },
      refetchQueries: [
        { query: GET_ACTIVE_USER }, // Query name
      ],
    }
  );

  useEffect(() => {
    if (data && data.activeCustomer) {
      const { firstName, lastName } = data.activeCustomer;
      setActiveUser({
        ...activeUser,
        firstName,
        lastName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) return <Loader color={"#B73232"} size={50} />;

  if (error) {
    console.log(error);
  }

  if (!data || !data.activeCustomer) return;

  const handleChange = (e) => {
    const { value, name } = e.target;

    setActiveUser({ ...activeUser, [name]: value });
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;

    setCurrentPass(value);
  };

  const onCloseSnack = (bool) => {
    setIsSnackOpen(bool);
  };

  const onUpdatePass = async () => {
    if (!oldPassword?.length || !newPassword?.length) return;
    try {
      const data = await updatePass();

      if (
        data &&
        data?.data?.updateCustomerPassword?.__typename === "Success"
      ) {
        setPasswordChanged(true);
        setMessage(t("auth.passwordChanged"));
        setIsSnackOpen(true);
        return;
      }
      if (
        data &&
        data?.data?.updateCustomerPassword?.__typename ===
          "InvalidCredentialsError"
      ) {
        setPasswordChanged(false);
        setMessage(data?.data?.updateCustomerPassword?.message);
        setIsSnackOpen(true);
      }

      if (data && data?.data?.resetPassword?.__typename === "ErrorResult") {
        setPasswordChanged(false);
        setMessage(data?.data?.updateCustomerPassword?.message);
        setIsSnackOpen(true);
      }
    } catch (err) {}
  };

  const onChangeEmail = async () => {
    if (!currentPass?.length || !emailAddress?.length) return;
    try {
      const data = await changeEmailAddress();

      if (
        data &&
        data?.data?.requestUpdateCustomerEmailAddress?.__typename === "Success"
      ) {
        setPasswordChanged(true);
        setMessage(t("auth.emailSent"));
        setIsSnackOpen(true);
        return;
      } else {
        setPasswordChanged(false);
        setMessage(data?.data?.requestUpdateCustomerEmailAddress?.message);
        setIsSnackOpen(true);
      }
    } catch (err) {}
  };

  return (
    <div className={classes.container}>
      <span className={`h2 ${classes.title}`}>{t("account.Settings")}</span>
      <div className={classes.security}>
        <span className={classes.innerTitles}>{t("account.Security")}</span>
        <div className={classes.editParams}>
          <div className={classes.formInput}>
            <FormInput
              name="pass"
              type="string"
              value={currentPass}
              handleChange={handleEmailChange}
              label={t("auth.Password")}
              margin={"15px 0px"}
            />
            <FormInput
              name="emailAddress"
              type="string"
              value={activeUser.emailAddress}
              handleChange={handleChange}
              label={t("account.newEmail")}
              margin={"20px 0px"}
            />
          </div>
          <span
            className={classes.change}
            onClick={async () => {
              await onChangeEmail();
            }}
          >
            {changeEmailAddressLoader ? (
              <Loader color={"#B73232"} size={20} />
            ) : (
              t("account.Change")
            )}
          </span>
        </div>
        <div className={classes.editParams}>
          <div className={classes.formInput}>
            <FormInput
              name="oldPassword"
              type="password"
              value={activeUser.oldPassword}
              label={t("auth.oldPassword")}
              handleChange={handleChange}
            />
            <FormInput
              name="newPassword"
              type="password"
              value={activeUser.newPassword}
              label={t("auth.newPassword")}
              handleChange={handleChange}
            />
          </div>
          <span
            className={classes.change}
            onClick={async () => {
              await onUpdatePass();
            }}
          >
            {updatePassLoader ? (
              <Loader color={"#B73232"} size={20} />
            ) : (
              t("account.Reset")
            )}
          </span>
        </div>
        <PreferredLanguage />
      </div>
      {/* <div className={classes.personalInfo}>
        <span className={classes.innerTitles}>
          {t("account.Personal Info")}
        </span>
        <div style={{ opacity: 0.8 }} className={classes.formInput}>
          <FormInput
            name="name"
            type="string"
            value={`${firstName} ${lastName}`}
            handleChange={() => {}}
          />
        </div>
        <span style={{ ...h5Medium, marginTop: 7 }}>
          {t("account.Date of Birth")}
        </span>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 16,
            width: "auto",
            maxWidth: 400,
          }}
        >
          <div className={classes.bdayDiv} style={{ width: 72, height: 55 }}>
            {t("common.Day")}
          </div>
          <div className={classes.bdayDiv} style={{ width: 176, height: 55 }}>
            {t("common.Month")}
          </div>
          <div className={classes.bdayDiv} style={{ width: 107, height: 55 }}>
            {t("common.Year")}
          </div>
        </div>
      </div> */}
      <Snackbar
        open={isSnackOpen}
        autoHideDuration={1000}
        onClose={() => onCloseSnack(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: `${t("css.snackBarPosition")}`,
        }}
      >
        <Alert
          onClose={() => onCloseSnack(false)}
          severity={passwordChanged ? "success" : "error"}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{message}</span>
          </div>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountSettings;
