import React, { useState } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import { h5Medium, h6Regular } from "../../../shared/constants";

import { ReactComponent as OrdersIcon } from "../../../assets/orders.svg";
import { ReactComponent as AddressIcon } from "../../../assets/address.svg";
import { ReactComponent as BankIcon } from "../../../assets/creditCard.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/settings.svg";
import { ReactComponent as ExitIcon } from "../../../assets/exit.svg";
import { useMutation } from "@apollo/client";
import { GET_ACTIVE_USER, LOGOUT } from "../../../queries/auth.queries";
import { useHistory } from "react-router-dom";
import { GET_TOTAL_LINES } from "../../../queries/cart.queries";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {},
  removeIcon: {
    height: 24,
    width: 24,
  },
  active: {
    ...h5Medium,
  },
  listItem: {
    ...h6Regular,
    opacity: 0.74,
  },
});

const iconsContainer = {
  Orders: <OrdersIcon />,
  Addresses: <AddressIcon />,
  // "Bank Cards": <BankIcon />,
  Settings: <SettingsIcon />,
};

const AccountMenu = ({ setCurrentPage }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(0);
  const history = useHistory();

  const [logout, { data }] = useMutation(LOGOUT, {
    refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_USER }],
  });

  const toggleClass = (index) => {
    setIsActive(index);
  };

  // optional callback
  if (data) {
  }

  const onLogout = async () => {
    await logout();
    localStorage.removeItem("isAddressSet"); 
    history.push("/");
    window.location.reload();
  };

  // Should be 4 pages
  // const pages = ["Orders", "Addresses", "Bank Cards", "Settings"];
  const pages = ["Orders", "Addresses", "Settings"];

  return (
    <div className={classes.container}>
      <List>
        {[
          `${t("account.Orders")}`,
          `${t("account.Addresses")}`,
          // `${t("account.BankCards")}`,
          `${t("account.Settings")}`,
        ].map((page, i) => (
          <ListItem
            button
            key={page}
            onClick={() => {
              history.push({
                pathname: "/account",
                state: {
                  page: pages[i],
                },
              });
            }}
          >
            <ListItemIcon>{iconsContainer[pages[i]]}</ListItemIcon>
            <ListItemText
              primary={page}
              className={`${
                isActive === i ? classes.active : classes.listItem
              }`}
              onClick={() => {
                toggleClass(i);
                setCurrentPage(page);
              }}
            />
          </ListItem>
        ))}
      </List>
      <List>
        <ListItem button key={t("menu.Sign Out")} onClick={() => onLogout()}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <ListItemText
            primary={t("menu.Sign Out")}
            className={classes.listItem}
          />
        </ListItem>
      </List>
    </div>
  );
};

export default AccountMenu;
