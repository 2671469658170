import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./footer.css";
import { AccountContext } from "../../context/account.context";

function Footer() {
  const { t } = useTranslation();
  const { accountState } = useContext(AccountContext);
  const dir = t("css.side");
  return (
    <footer className="footer dark" style={{ direction: dir }}>
      <div className={`footer__logo ${t("css.footerLogoDesign")}`}>
        {accountState?.sharedData?.TITLE}
      </div>
      <div className="footer__middle-divs">
        <div className="footer__middle-divs__inner-div">
          <span className="footer__middle-divs__title h5-medium">
            {t("footer.About")}
          </span>
          <div className="footer__middle-divs__links">
            <span className="footer__middle-divs__link h6-regular">
              {t("common.Our Suppliers")}
            </span>
          </div>
        </div>
        <div className="footer__middle-divs__inner-div">
        </div>
        <div className="footer__middle-divs__inner-div">
        </div>

        <div className={`footer__last-div ${t("css.footerLastDivDesign")}`}>
          <span className="footer__middle-divs__title h5-medium">
            {t("footer.Need Help")}
          </span>
          <div
            className="h6-regular footer__customer-care-link"
            style={{ paddingRight: dir === "ltr" ? 16 : 0 }}
          >
            <span>{t("footer.Visit customer care")}</span>
            <i> </i>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
