import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from "@material-ui/core/styles";

//Components
import Header from "./components/header/Header";
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ApolloProvider } from "@apollo/client/react";
import Item from "./components/item-page/item-page";
import Footer from "./components/footer/footer";
import CollectionsOverviewContainer from "./components/catalog/catalog-main/catalog-main.container";

import "./App.css";

import { FilterContextProvider } from "./context/filterContext";
import { CartContextProvider } from "./context/cartContext";
import SupplierPage from "./components/supplier-page/supplier-page.component";
import { HeaderContextProvider } from "./context/headerContext";
import { useTranslation } from "react-i18next";
import { AccountContextProvider } from "./context/account.context";
import Account from "./components/account/account.component";
import Checkout from "./components/checkout/checkout.component";
import ResetPassword from "./components/auth/reset-passowrd/reset-password.component";
import HomePage from "./components/home-page/home-page";
import AppBootstrap from "./components/app-bootstrap/app-bootstrap.component";
import OrderRecieved from "./components/checkout/order-recieved/order-recieved.component";
import { ErrorHandlingContextProvider } from "./context/error-handling.context";
import configData from "./config/config.json";
import PaymentError from "./components/checkout/payment-error/payment-error.component";

function App() {
  const { t } = useTranslation();

  const httpLink = new HttpLink({
    uri: configData.BASE_URL + `languageCode=${t("graphql.languageCode")}`,
  });

  // Setup the header for the request
  const middlewareAuthLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem("token");

    const authorizationHeader = token ? `Bearer ${token}` : null;
    operation.setContext({
      headers: {
        authorization: authorizationHeader,
      },
    });
    return forward(operation);
  });

  //After the backend responds, we take the refreshToken from headers if it exists, and save it in the cookie.
  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const context = operation.getContext();
      const {
        response: { headers },
      } = context;

      if (headers) {
        const refreshToken = headers.get("vendure-auth-token");
        if (refreshToken) {
          localStorage.setItem("token", refreshToken);
        }
      }

      return response;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const client = new ApolloClient({
    link: from([errorLink, middlewareAuthLink, afterwareLink, httpLink]),
    cache: new InMemoryCache(),
    credentials: "include",
  });
  localStorage.setItem("lang", t("graphql.languageCode"));

  const useStyles = makeStyles({
    main: {
      margin: "0px 40px",
      "@media (max-width:940px)": {
        margin: "0px 16px",
      },
    },
  });

  const classes = useStyles();

  return (
    <ApolloProvider client={client}>
      <ErrorHandlingContextProvider>
        <HeaderContextProvider>
          <AccountContextProvider>
            <CartContextProvider>
              <FilterContextProvider>
                <BrowserRouter>
                  <AppBootstrap />
                  <div className="app" style={{ direction: t("css.side") }}>
                    <Header />
                    <Switch>
                      <>
                      <main className="main">
                        <Route path="/home" exact component={HomePage} />
                        <div className={classes.main}>
                          <Route path="/item/:id" exact component={Item} />
                          <Route
                            path="/supplier/:id"
                            exact
                            component={SupplierPage}
                          />
                          <Route
                            path="/catalog"
                            exact
                            component={CollectionsOverviewContainer}
                          />
                          <Route path="/account" exact component={Account} />
                          <Route path="/checkout" exact component={Checkout} />
                          <Route
                            path="/success"
                            exact
                            component={OrderRecieved}
                          />
                          <Route path="/error" exact component={PaymentError} />
                          <Route
                            path="/reset-password"
                            exact
                            component={ResetPassword}
                          />
                        </div>
                        <Route path="/" exact component={HomePage} />
                      </main>
                      </>
                    </Switch>
                  </div>
                  <Footer />
                  <div className="footer-background dark"></div>
                </BrowserRouter>
              </FilterContextProvider>
            </CartContextProvider>
          </AccountContextProvider>
        </HeaderContextProvider>
      </ErrorHandlingContextProvider>
    </ApolloProvider>
  );
}

export default App;
