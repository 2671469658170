import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import {
  h4Medium,
  h5Medium,
  h6Medium,
  h6Regular,
  pointer,
} from "../../../shared/constants";
import { ReactComponent as OrderRecievedIcon } from "../../../assets/delivery.svg";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

const useStyles = makeStyles({
  container: {
    background: "#FFFFFF",
    width: "100%",
    padding: "48px 0",
    borderRadius: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    margin: "0 auto",
  },

  title: {
    ...h4Medium,
    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },

  subTitle: {
    marginTop: 16,
    ...h6Regular,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  trackOrder: {
    marginTop: 16,
    marginBottom: 24,
    ...h6Medium,
    color: "#80BB34",
    ...pointer,
  },

  button: {
    height: 56,
    width: "100%",
    maxWidth: 320,
    border: "none",
    textAlign: "center",
    background: "#80BB34",
    borderRadius: 16,
    marginTop: 24,
    ...h5Medium,
    color: "#FFFFFF",
    ...pointer,
  },
});

const OrderRecieved = () => {
  const classes = useStyles();
  const [orderCode, setOrderCode] = useState("");
  const { t } = useTranslation();

  const history = useHistory();

  const { loading, error } = useQuery(GET_ACTIVE_USER, {
    onCompleted: (data) => {
      if (data?.activeCustomer?.orders?.items[0]?.code) {
        setOrderCode(data?.activeCustomer?.orders?.items[0]?.code);
      }
    },
  });

  return (
    <>
      <div style={{ paddingTop: 144 }}></div>
      <div className={classes.container}>
        <span className={classes.title}>{t("checkout.Order Received")}</span>
        <div className={classes.subTitle}>
          <span>
            {t("checkout.orderMsg", {
              orderCode: orderCode,
            })}
          </span>
          <span>{t("checkout.Great choice")}</span>
        </div>

        <span
          className={classes.trackOrder}
          onClick={() => {
            history.push({
              pathname: "/account",
              state: {
                page: "Orders",
              },
            });
          }}
        >
          {t("checkout.Track Order")}
        </span>

        <OrderRecievedIcon style={{ width: "100%" }} />

        <button
          className={classes.button}
          onClick={() => {
            history.push("/catalog");
          }}
        >
          {t("checkout.Continue Shopping")}
        </button>
      </div>
    </>
  );
};

export default OrderRecieved;
