import React, { useState } from "react";

import { makeStyles } from "@material-ui/core";
import { h5Medium, h5Regular } from "../../../shared/constants";
import { useHistory } from "react-router-dom";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { ReactComponent as MinusIcon } from "../../../assets/minus.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_TO_CART,
  GET_ACTIVE_ORDER,
  GET_TOTAL_LINES,
  REMOVE_FROM_CART,
  REMOVE_ORDER_LINE,
} from "../../../queries/cart.queries";
import Loader from "../../common/loader/loader.component";
import { MOVE_STATE } from "../../../queries/checkout.queries";
import { GET_ACTIVE_USER } from "../../../queries/auth.queries";

const useStyles = makeStyles({
  container: {
    width: 280,
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "space-around",
    alignItems: "center",

    "@media (max-width:940px)": {
      width: "100%",
    },
  },
  button: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    width: 112,
    height: 40,
    background: "#E8E8E8",
    borderRadius: 8,
  },

  amount: {
    ...h5Regular,
    opacity: 0.64,
    maxWidth: 35,
    border: "none",
    textAlign: "center",
  },

  price: {
    width: 80,
    height: 24,
    ...h5Medium,
    opacity: 0.72,
  },
});

const CartActions = ({ item }) => {
  const classes = useStyles();
  const history = useHistory();

  const totalPerItem = (item?.linePriceWithTax / 100).toFixed(2);

  const [amountValue, setAmountValue] = useState(+item.quantity);

  const { data: activeOrder } = useQuery(GET_ACTIVE_ORDER);

  const [moveStateToAddingItems] = useMutation(MOVE_STATE, {
    variables: {
      state: "AddingItems",
    },
    refetchQueries: [{ query: GET_ACTIVE_USER }],
    onCompleted: async () => await addToCart(),
  });

  const addItemToOrder = async () => {
    if (
      !activeOrder?.activeOrder ||
      activeOrder?.activeOrder.state === "AddingItems"
    ) {
      await addToCart();
    }
    if (activeOrder?.activeOrder.state === "ArrangingPayment") {
      await moveStateToAddingItems();
    }
  };

  const [addToCart, { loading: addedLoading }] = useMutation(ADD_TO_CART, {
    variables: {
      variantId: item.productVariant.id,
      qty: 1,
    },
    refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
  });

  const [removeFromCart, { loading: adjustLineLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: item.id,
        qty: amountValue - 1,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const [removeOrderLine, { loading: lineRemovedLoading }] = useMutation(
    REMOVE_ORDER_LINE,
    {
      variables: {
        id: item?.id,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const [adjustOnChange, { loading: adjustOnChangeLoading }] = useMutation(
    REMOVE_FROM_CART,
    {
      variables: {
        id: item.id,
        qty: amountValue,
      },
      refetchQueries: [{ query: GET_TOTAL_LINES }, { query: GET_ACTIVE_ORDER }],
    }
  );

  const onEditCartAmount = async (bol) => {
    if (isNaN(amountValue)) return;

    if (bol) {
      await addItemToOrder();
      setAmountValue(amountValue + 1);
    } else {
      if (amountValue === 1) return;
      await removeFromCart();
      setAmountValue(amountValue - 1);
    }
  };

  const onRemoveItem = async () => {
    await removeOrderLine();
  };

  const changeOrderQty = async (qty) => {
    if (isNaN(qty)) return;

    if (qty === 0) {
      await setAmountValue(+qty);
      await removeOrderLine();
      return;
    }

    if (qty !== 0 && amountValue !== 0) {
      await setAmountValue(+qty);
      await adjustOnChange();
    }
  };

  const isDeleteIcon = () => {
    if (history.location.pathname === '/checkout' && activeOrder?.activeOrder?.lines?.length === 1) return false;
    return true;
  };

  const minusIcon =
    addedLoading ||
      lineRemovedLoading ||
      adjustLineLoading ||
      adjustOnChangeLoading ? (
      <Loader color={"#B73232"} size={15} />
    ) : (
      <MinusIcon
        style={{ width: "18%" }}
        className="pointer"
        onClick={() => onEditCartAmount(false)}
      />
    );

  const plusIcon =
    addedLoading ||
      lineRemovedLoading ||
      adjustLineLoading ||
      adjustOnChangeLoading ? (
      <Loader color={"#B73232"} size={15} />
    ) : (
      <PlusIcon
        style={{ width: "18%" }}
        className="pointer"
        onClick={() => onEditCartAmount(true)}
      />
    );

  return (
    <div className={classes.container}>
      <button className={classes.button}>
        {amountValue === 1 || amountValue === 0 ? (
          <div style={{ width: "18%" }}></div>
        ) : (
          minusIcon
        )}
        <input
          className={classes.amount}
          value={amountValue}
          type="number"
          min="0"
          max="99"
          onChange={(e) => {
            if (isNaN(e.target.value)) return;
            if (adjustOnChangeLoading) return;
            // setAmountValue(e.target.valueAsNumber);
            changeOrderQty(e.target.valueAsNumber);
          }}
        />
        {amountValue === 0 ? <div style={{ width: "18%" }}></div> : plusIcon}
      </button>
      <span className={classes.price}>₪{totalPerItem}</span>
      <i onClick={onRemoveItem} className="pointer">
        {lineRemovedLoading ? (
          <Loader color={"#B73232"} size={15} />
        ) : (
          <>{isDeleteIcon() && <DeleteIcon />}</>
        )}
      </i>
    </div>
  );
};

export default CartActions;
