import React from "react";
import clsx from "clsx";
import BrokenImageIcon from "../../common/item-card/broken-image";
import "./item-page-image-galery.component.css";

const VariantAssets = ({ data, variant, mainAsset, onChange }) => {
  return (
    <>
      {variant?.assets?.map((asset, i) => (
        <img
          key={asset.id}
          className={clsx({
            "IPIG__image-container--image-gallery-img": true,
            selected: asset === mainAsset
          })}
          src={asset.preview}
          alt={data?.product?.name}
          onClick={() => onChange(variant, asset)}
        />
      ))}
    </>
  );
};

const ItemPageImageGalery = ({ data, currentVariant, onVariantChange }) => {
  const allButCurrentVariants = (data?.product?.variants || []).filter(
    (v) => v?.id !== currentVariant?.id
  );

  const product = data?.product;

  const [mainAsset, setMainAsset] = React.useState(currentVariant?.assets[0]);

  React.useEffect(() => {
    setMainAsset(currentVariant?.assets[0]);
  }, [currentVariant]);

  const onChange = (variant, asset) => {
    setMainAsset(asset);
    onVariantChange && variant?.id !== product?.id && onVariantChange(variant);
  };

  return (
    <div className="IPIG__image-container">
      <div className="IPIG__image-container--image">
        {mainAsset ? (
          <img
            className="IPIG__image-container--img"
            src={mainAsset?.preview}
            alt={product?.name}
          ></img>
        ) : (
          <BrokenImageIcon style={{ width: "100%", height: "100%" }} />
        )}
      </div>
      <div className="IPIG__image-container--image-gallery">
        <VariantAssets onChange={onChange} data={data} variant={product} />
        {product?.variants?.map((variant) => (
          <VariantAssets
            mainAsset={mainAsset}
            key={variant.id}
            data={data}
            variant={variant}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};

export default ItemPageImageGalery;
