import { makeStyles } from "@material-ui/core";
import React from "react";

import { FireworkSpinner } from "react-spinners-kit";

const useStyles = makeStyles({
  loader: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const Loader = ({ size, color }) => {
  const classes = useStyles();

  return (
    <div className={classes.loader}>
      <FireworkSpinner color={color} size={size} loading={true} />
    </div>
  );
};

export default Loader;
