export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wedensday",
  "Thursday",
  "Friday",
  "Suterday",
];

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const hours = [
  "07:00-10:00",
  "10:00-13:00",
  "13:00-16:00",
  "16:00-19:00",
  "19:00-22:00",
];

export const getToday = () => {
  return {
    day: days[new Date(Date.now()).getDay() + 1],
    month: months[new Date(Date.now()).getMonth()],
    date: new Date(Date.now()).getDate() + 1,
  };
};

export function adjustDatesArr(pickDays) {
  return [
    {
      date: {
        day: days[
          new Date(new Date().setDate(new Date().getDate() + pickDays)).getDay()
        ],
        month:
          months[
            new Date(
              new Date().setDate(new Date().getDate() + pickDays)
            ).getMonth()
          ],
        date: new Date(
          new Date().setDate(new Date().getDate() + pickDays)
        ).getDate(),
      },
      hours,
    },
    {
      date: {
        day: days[
          new Date(
            new Date().setDate(new Date().getDate() + (pickDays + 1))
          ).getDay()
        ],
        month:
          months[
            new Date(
              new Date().setDate(new Date().getDate() + (pickDays + 1))
            ).getMonth()
          ],
        date: new Date(
          new Date().setDate(new Date().getDate() + (pickDays + 1))
        ).getDate(),
      },
      hours,
    },
    {
      date: {
        day: days[
          new Date(
            new Date().setDate(new Date().getDate() + (pickDays + 2))
          ).getDay()
        ],
        month:
          months[
            new Date(
              new Date().setDate(new Date().getDate() + (pickDays + 2))
            ).getMonth()
          ],
        date: new Date(
          new Date().setDate(new Date().getDate() + (pickDays + 2))
        ).getDate(),
      },
      hours,
    },
  ];
}
