import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";

import { GET_ORDER_BY_CODE } from "../../../queries/cart.queries";
import {
  h4Medium,
  h5Semibold,
  h6Medium,
  h6Regular,
  h7Regular,
  pointer,
  h5Medium,
} from "../../../shared/constants";
// import { ReactComponent as HeartIcon } from "../../../assets/heart.svg";
import { ReactComponent as BasketIcon } from "../../../assets/basketadd.svg";
import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as DeliveryTruckIcon } from "../../../assets/deliveryTruck.svg";
// import { ReactComponent as CreditCardIcon } from "../../../assets/credit-card.svg";
import { ReactComponent as ScheduleIcon } from "../../../assets/schedule.svg";
// import { ReactComponent as RecurringIcon } from "../../../assets/recurring.svg";
import Loader from "../../common/loader/loader.component";
import OrderTimeLine from "../../common/order-time-line/order-time-line.component";

const useStyles = makeStyles({
  container: {
    width: 1064,
    height: "100%",
    maxHeight: 760,
    borderRadius: 32,
    display: "flex",
    background: "#FFFFFF",
    border: "none",
    position: "relative",
  },

  header: {
    background: "#FFFFFF",
    width: "65%",
    padding: 48,
  },

  subHeader: {
    marginTop: 40,
    display: "flex",
    justifyContent: "center",
  },

  lines: {
    marginTop: 40,
    borderTop: "1px dashed #15222F",
    maxHeight: 220,
    overflow: "auto",
  },

  line: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 24,
    paddingTop: 24,
    borderBottom: "1px dashed #15222F",
  },
});

const AccountDisplayOrder = ({
  code,
  handleClose,
  onAddLineToBasket,
  onAddToBasket,
  addToCardLoader,
}) => {
  const { t } = useTranslation();
  const dir = t("css.side");
  const [idSelected, setIdSelected] = useState(null);

  const {
    loading,
    error,
    data: orderData,
  } = useQuery(GET_ORDER_BY_CODE, {
    variables: {
      code: code,
    },
  });

  const classes = useStyles();

  if (loading) return <Loader color={"#B73232"} size={70} />;

  if (error) {
    console.log(error);
  }

  const data = orderData?.orderByCode;

  const addressData = data?.shippingAddress;
  const crossIconPosition = dir === "ltr" ? { right: 48 } : { left: 48 };
  const backgroundDivPosition = dir === "ltr" ? { right: 0 } : { left: 0 };

  return (
    <div className={classes.container} style={{ direction: dir }}>
      <div
        className={classes.header}
        style={{ borderRadius: dir === "ltr" ? "32px 0 0 32px" : 32 }}
      >
        <span style={{ ...h4Medium, opacity: 0.8 }}>
          {t("account.order")} #{code}
        </span>
        <div className={classes.subHeader}>
          <OrderTimeLine state={data?.state} />
        </div>
        <div className={classes.lines}>
          {data?.lines.map((line) => (
            <div className={classes.line} key={line?.id}>
              <div style={{ height: 56, width: 56 }}>
                <img
                  src={line?.featuredAsset?.preview}
                  alt={line?.featuredAsset?.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: 8,
                  }}
                />
              </div>
              <span style={{ maxWidth: 168, width: "100%", ...h6Regular }}>
                {line?.productVariant?.name}
              </span>
              {/* <Tooltip title={`${t("account.addToWishList")}`} placement="top">
                <HeartIcon
                  style={{
                    ...pointer,
                  }}
                />
              </Tooltip> */}

              <Tooltip
                title={`${t("account.copyLineToBasket")}`}
                placement="top"
              >
                <div
                  style={{
                    height: 56,
                    width: 56,
                    background: "#80BB34",
                    borderRadius: 8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    ...pointer,
                  }}
                  onClick={() => {
                    setIdSelected(line?.id);
                    onAddLineToBasket(line);
                  }}
                >
                  {idSelected === line?.id && addToCardLoader ? (
                    <Loader color={"#B73232"} size={30} />
                  ) : (
                    <BasketIcon />
                  )}
                </div>
              </Tooltip>

              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <span style={{ ...h7Regular, marginBottom: 4 }}>
                  {t("account.Qty")}
                </span>
                <span
                  style={{
                    fontFamily: "Barlow",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "20px",
                    lineHeight: "24px",
                    color: "#15222F",
                    opacity: 0.64,
                  }}
                >
                  {line?.quantity}
                </span>
              </span>
              <span style={{ ...h6Medium }}>
                {t("coins.current")}
                {(line?.unitPriceWithTax / 100).toFixed(2)}
              </span>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("account.totalProducts")}
          </span>
          <span style={{ ...h6Medium, opacity: 0.72 }}>
            ₪ {(data?.subTotalWithTax / 100).toFixed(2)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("account.delivery")}
          </span>
          <span style={{ ...h6Medium, opacity: 0.72 }}>
            ₪ {(data?.shipping / 100).toFixed(2)}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 26,
          }}
        >
          <span style={{ ...h6Medium, opacity: 0.64 }}>
            {t("common.total")}
          </span>
          <span style={{ ...h5Semibold, opacity: 0.8 }}>
            ₪ {(data?.totalWithTax / 100).toFixed(2)}
          </span>
        </div>
      </div>
      <div
        style={{
          width: "35%",
          borderRadius: 32,
          padding: 48,
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div className="pointer">
          <RemoveIcon
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              ...crossIconPosition,
              ...pointer,
              zIndex: 50,
            }}
            onClick={handleClose}
          />
        </div>

        <div style={{ marginBottom: 56 }}>
          <div
            style={{
              display: "flex",
              marginBottom: 24,
            }}
          >
            <DeliveryTruckIcon
              style={{
                width: 20,
                height: 20,
                marginRight: dir === "ltr" ? 18 : 0,
              }}
            />
            <span style={{ ...h5Medium }}>{t("account.deliveryTo")}</span>
          </div>
          <div
            style={{
              ...h6Regular,
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{data?.customer?.firstName} {data?.customer?.lastName}</span>
            <span>{addressData?.streetLine1}</span>
            <span>
              {t("common.phone")}: {addressData?.phoneNumber}
            </span>
          </div>
        </div>

        {/* <div style={{ marginBottom: 56 }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <CreditCardIcon
              style={{ width: 20, height: 20, marginRight: 18 }}
            />
            <span style={{ ...h5Medium }}>{t("checkout.Payment Method")}</span>
          </div>
          <div>Comming Soon...</div>
        </div> */}

        <div style={{ marginBottom: 56 }}>
          <div
            style={{
              display: "flex",
            }}
          >
            <ScheduleIcon
              style={{
                width: 20,
                height: 20,
                marginRight: dir === "ltr" ? 18 : 0,
              }}
            />
            <span style={{ ...h5Medium }}>{t("account.deliverySchedule")}</span>
          </div>
          <div
            style={{
              ...h6Regular,
              marginTop: 16,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {data?.timeWindow?.description}
          </div>
        </div>
        {/* 
        <button
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            width: 310,
            padding: 20,
            background: "#80BB34",
            borderRadius: 16,
            color: "#ffffff",
            textDecoration: "none",
            ...h5Medium,
            zIndex: 50,
            ...pointer,
          }}
          disabled={addToCardLoader}
          onClick={() => {
            onAddToBasket(data);
          }}
        >
          {t("account.copyOrderToBasket")}
        </button> */}

        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 32,
            color: "#80BB34",
            ...h6Medium,
          }}
        >
          <RecurringIcon style={{ marginRight: 10 }} />
          <span>{t("account.makeThisOrderRecurring")}</span>
        </div> */}
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 32,
            color: "#80BB34",
            ...h6Medium,
          }}
        >
          <HeartIcon />
          <span>{t("account.addToWishList")}</span>
        </div> */}
      </div>

      <div
        style={{
          position: "absolute",
          ...backgroundDivPosition,
          top: 0,
          height: "100%",
          width: "37%",
          background: "#80BB34",
          opacity: 0.1,
          borderRadius: 32,
        }}
      ></div>
    </div>
  );
};

export default AccountDisplayOrder;
