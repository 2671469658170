import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router";

import { useQuery } from "@apollo/client";

import CatalogMain from "./catalog-main";

import {
  GET_COLLECTIONS,
  GET_ITEMS_WITH_FILTERS,
} from "../../../queries/queries";
import { FilterContext } from "../../../context/filterContext";
import "./catalog-main.css";
import Loader from "../../common/loader/loader.component";

const _ = require("lodash");

const GET_ITEMS = GET_ITEMS_WITH_FILTERS;

const CollectionsOverviewContainer = () => {
  window.scrollTo(0, 0);
  const location = useLocation();
  const { searchState, setSearchState } = useContext(FilterContext);
  let { term, facetValueIds, collectionId } = searchState;

  const factValuesIds = facetValueIds.map((fv) => fv.id);
  const collectionIds = collectionId ? collectionId["id"] : null;

  const [state, setState] = useState({
    bottom: false,
  });

  let search = new URLSearchParams(location.search).get("search");
  let category = new URLSearchParams(location.search).get("category");
  let facets = new URLSearchParams(location.search).get("facets");

  const { data: colleactionsData } = useQuery(GET_COLLECTIONS);

  useEffect(() => {
    if (search && search !== "undefined") {
      setSearchState({
        ...searchState,
        term: search,
      });
    }
    if (category && category !== "undefined") {
      const col = colleactionsData?.collections?.items.filter(
        (c) => c?.id === category
      );

      if (col && col.length) {
        setSearchState({
          ...searchState,
          facetValueIds: [],
          collectionId: col[0],
          facetFaluesPerCollectionInit: true,
        });
      }
    }
    if (facets && facets !== "undefined") {
      // setSearchState({
      //   ...searchState,
      //   facetValueIds: facets,
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, category, facets, colleactionsData]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const { loading, error, data } = useQuery(GET_ITEMS, {
    variables: {
      term,
      facetValueIds: factValuesIds,
      collectionId: collectionIds,
      groupByProduct: true,
    },
  });

  if (error) return `Error! ${error.message}`;

  const filters = _(data?.search?.facetValues)
    .groupBy("facetValue.facet.name")
    .mapValues((v) => _.sortBy(v, "facetValue.name"))
    .toPairs()
    .sortBy(0)
    .fromPairs()
    .value();

  const items = data?.search?.items;

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
          }}
        >
          <Loader color={"#B73232"} size={100} />
        </div>
      ) : (
        <>
          <div style={{ paddingTop: 144 }}></div>
          <CatalogMain
            filters={filters}
            items={items}
            toggleDrawer={toggleDrawer}
            state={state}
            setState={setState}
          />
        </>
      )}
    </>
  );
};

export default CollectionsOverviewContainer;
