import { useQuery } from "@apollo/client";
import { GET_ITEMS_WITH_FILTERS } from "../../../queries/queries";
import ItemCard from "../../common/item-card/item-card";
import React from "react";

import "./home-products.component.css";
import Loader from "../../common/loader/loader.component";
import { useHistory } from "react-router";

const HomeProductsDesk = ({ collectionId, cName }) => {
  const history = useHistory();
  const showItem = (x) => {
    history.push(`/item/${x}`);
  };

  const { loading, data } = useQuery(GET_ITEMS_WITH_FILTERS, {
    variables: {
      collectionId,
      groupByProduct: true,
      take: 10,
      skip: 0,
    },
  });

  if (loading) return <Loader color={"#B73232"} size={100} />;

  const productsArr = data?.search.items;

  return (
    <HomeProductsDesktop
      cName={cName}
      productsArr={productsArr}
      showItem={showItem}
    />
  );
};

const HomeProductsDesktop = ({ cName, productsArr, showItem }) => (
  <div className={`${cName}`}>
    {productsArr?.map((item, i) => {
      const price =
        item?.priceWithTax.min || (item?.priceWithTax?.value / 100).toFixed(2);
      return (
        <ItemCard
          styles={{
            itemCard: {
              margin: "0px 16px 0px 0px",
            },
          }}
          itemCard={item}
          id={item?.productVariantId}
          productVariantName={item?.productVariantName}
          key={i}
          title={item?.merchantName}
          img={item?.productAsset?.preview}
          description={item?.productName}
          weight={item?.productVariantPriceMeasurement}
          price={price}
          currencyCode={item?.currencyCode}
          merchantLogo={item?.merchantAsset?.preview}
          showItem={() => {
            showItem(item?.slug);
          }}
        />
      );
    })}
  </div>
);

export default HomeProductsDesk;
