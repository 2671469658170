/* eslint-disable no-use-before-define */
import { gql } from "@apollo/client";

const channelsQuery = `channels {
    id
    token
    code
    permissions
}`;

const LOGIN_WITH_CHANNELS = gql`
  mutation ($username: String!, $password: String!, $rememberMe: Boolean) {
    login(username: $username, password: $password, rememberMe: $rememberMe) {
      ... on CurrentUser {
        id
        identifier
        ${channelsQuery}
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
      ... on ErrorResult {
        errorCode
        message
      }
      ... on NotVerifiedError {
        errorCode
      }
      __typename
    }
  }
`;

const LOGIN = gql`
  mutation ($username: String!, $password: String!, $rememberMe: Boolean) {
    login(username: $username, password: $password, rememberMe: $rememberMe) {
      ... on CurrentUser {
        id
        identifier
      }
      ... on InvalidCredentialsError {
        errorCode
        message
        authenticationError
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
      }
      ... on ErrorResult {
        errorCode
        message
      }
      ... on NotVerifiedError {
        errorCode
      }
      __typename
    }
  }
`;

const LOGOUT = gql`
  mutation {
    logout {
      success
      __typename
    }
  }
`;

const GET_ACTIVE_USER = gql`
  query {
    activeCustomer {
      id
      createdAt
      updatedAt
      title
      firstName
      lastName
      phoneNumber
      emailAddress
      addresses {
        id
        createdAt
        updatedAt
        company
        streetLine1
        streetLine2
        city
        province
        postalCode
        fullName
        country {
          id
          createdAt
          updatedAt
          languageCode
          code
          name
          enabled
          translations {
            id
            createdAt
            updatedAt
            languageCode
            name
          }
        }
        phoneNumber
        defaultShippingAddress
        defaultBillingAddress
        customFields
      }
      orders {
        items {
          id
          createdAt
          updatedAt
          code
          state
          active
          customer {
            id
            createdAt
            updatedAt
            title
            firstName
            lastName
            phoneNumber
            emailAddress
            user {
              id
              createdAt
              updatedAt
              identifier
              verified
            }
            customFields
          }
          shippingAddress {
            fullName
            company
            streetLine1
            streetLine2
            city
            province
            province
            postalCode
            country
            countryCode
            phoneNumber
            phoneNumber
            phoneNumber
          }
          billingAddress {
            fullName
            company
            streetLine1
            streetLine2
            city
            province
            postalCode
            country
            countryCode
            phoneNumber
          }
          lines {
            quantity
            id
            createdAt
            updatedAt
            productVariant {
              id
              name
              featuredAsset {
                id
                source
              }
            }
            featuredAsset {
              id
            }
          }
          couponCodes
          promotions {
            id
          }
          payments {
            id
            state
            transactionId
          }
          fulfillments {
            id
            createdAt
            updatedAt
            orderItems {
              id
            }
            method
          }
          subTotal
          currencyCode
          shipping
          shippingWithTax
          total
          totalWithTax
          history {
            items {
              id
            }
          }
          customFields
        }
        totalItems
      }
    }
  }
`;

const REGISTER_USER = gql`
  mutation (
    $emailAddress: String!
    $password: String
    $title: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
  ) {
    registerCustomerAccount(
      input: {
        emailAddress: $emailAddress
        password: $password
        title: $title
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
      }
    ) {
      ... on Success {
        success
        __typename
      }
      ... on MissingPasswordError {
        errorCode
        message
        __typename
      }
      ... on NativeAuthStrategyError {
        errorCode
        message
        __typename
      }
      ... on ErrorResult {
        errorCode
        message
        __typename
      }
      __typename
    }
  }
`;

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordRest($emailAddress: String!) {
    requestPasswordReset(emailAddress: $emailAddress) {
      ... on Success {
        success
        __typename
      }

      ... on ErrorResult {
        errorCode
        message
        __typename
      }
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      ... on CurrentUser {
        id
        identifier
        __typename
      }

      ... on ErrorResult {
        errorCode
        message
        __typename
      }
      __typename
    }
  }
`;

const CREATE_ADDRESS = gql`
  mutation CreateAddress(
    $fullName: String
    $company: String
    $streetLine1: String!
    $streetLine2: String
    $city: String
    $province: String
    $postalCode: String
    $countryCode: String!
    $phoneNumber: String
    $defaultShippingAddress: Boolean
    $defaultBillingAddress: Boolean
  ) {
    createCustomerAddress(
      input: {
        fullName: $fullName
        company: $company
        streetLine1: $streetLine1
        streetLine2: $streetLine2
        city: $city
        province: $province
        postalCode: $postalCode
        countryCode: $countryCode
        phoneNumber: $phoneNumber
        defaultShippingAddress: $defaultShippingAddress
        defaultBillingAddress: $defaultBillingAddress
      }
    ) {
      ...Address
      __typename
    }
  }
  fragment Address on Address {
    id
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country {
      id
      code
      name
      __typename
    }
    phoneNumber
    defaultShippingAddress
    defaultBillingAddress
    __typename
  }
`;

const UPDATE_PASSWORD = gql`
  mutation ChangePassword($old: String!, $new: String!) {
    updateCustomerPassword(currentPassword: $old, newPassword: $new) {
      ... on Success {
        success
        __typename
      }
      ...ErrorResult
      __typename
    }
  }
  fragment ErrorResult on ErrorResult {
    errorCode
    message
    __typename
  }
`;

const CHANGE_EMAIL_ADDRESS = gql`
  mutation ChangeEmailAddress($password: String!, $emailAddress: String!) {
    requestUpdateCustomerEmailAddress(
      password: $password
      newEmailAddress: $emailAddress
    ) {
      ... on Success {
        success
        __typename
      }
      ...ErrorResult
      __typename
    }
  }
  fragment ErrorResult on ErrorResult {
    errorCode
    message
    __typename
  }
`;

export {
  LOGOUT,
  LOGIN,
  LOGIN_WITH_CHANNELS,
  GET_ACTIVE_USER,
  REGISTER_USER,
  CREATE_ADDRESS,
  REQUEST_PASSWORD_RESET,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  CHANGE_EMAIL_ADDRESS,
};
