import React from "react";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as RemoveIcon } from "../../../assets/cross.svg";
import { ReactComponent as EmptyIcon } from "../../../assets/empty.svg";
import {
  h4Light,
  h4Medium,
  h5Light,
  h5Medium,
  h5Semibold,
  h6Medium,
  h7Medium,
  pointer,
} from "../../../shared/constants";
import CartItem from "../cart-item/cart-item.component";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { GET_ACTIVE_ORDER } from "../../../queries/cart.queries";

const useStyles = makeStyles({
  container: {
    padding: "44px 40px",
    width: 680,
    background: "#FFFFFF",

    "@media (max-width:940px)": {
      padding: "20px 24px",
      width: 375,
    },

    "@media (max-width:380px)": {
      padding: "20px 24px",
      width: 250,
    },
  },

  header: {
    display: "flex",
    height: 56,
    justifyContent: "space-between",
    alignContent: "center",
    alignItems: "center",

    "@media (max-width:940px)": {
      height: 32,
    },
  },

  headerTitles: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    width: 230,

    "@media (max-width:940px)": {
      width: 180,
    },
  },
  headerBasket: {
    ...h4Medium,

    "@media (max-width:940px)": {
      ...h5Medium,
    },
  },
  headerWish: {
    ...h4Light,
    opacity: 0.64,

    "@media (max-width:940px)": {
      ...h5Light,
      opacity: 0.64,
    },
  },

  closeIcon: {
    width: 32,
    height: 32,
    cursor: pointer,

    "@media (max-width:940px)": {
      width: 16,
      height: 16,
    },
  },

  emptyContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 42,
    height: 368,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",

    "@media (max-width:940px)": {
      marginTop: 24,
    },
  },

  title: {
    height: 56,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  empty: {
    ...h6Medium,
  },

  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    width: 310,
    height: 56,
    background: "#80BB34",
    borderRadius: 16,
    color: "#ffffff",
    textDecoration: "none",
    ...h5Medium,

    "@media (max-width:940px)": {
      width: "100%",
    },
  },

  total: {
    marginTop: 24,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "@media (max-width:940px)": {
      marginRight: 0,
    },
  },

  totalTitle: {
    ...h6Medium,
    opacity: 0.8,

    "@media (max-width:940px)": {
      ...h7Medium,
    },
  },

  totalNumber: {
    ...h5Semibold,
    opacity: 0.72,
  },
});

const CartDrawer = ({ toggleCart }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data } = useQuery(GET_ACTIVE_ORDER);

  let lines = [];
  if (data && data.activeOrder) {
    lines = data.activeOrder.lines;
  }
  const header = (
    <div className={classes.header}>
      <div className={classes.headerTitles}>
        <span className={classes.headerBasket}>{t("cart.Basket")}</span>
        {/* <span className={classes.headerWish}>{t("cart.Wishlist")} 0</span> */}
      </div>
      <div className="pointer" onClick={toggleCart}>
        <RemoveIcon className={classes.closeIcon} />
      </div>
    </div>
  );

  const cartLists = (
    <div>
      {lines.length ? (
        <React.Fragment>
          {lines.map((item) => (
            <CartItem key={item.id} item={item} />
          ))}
          <div
            className={classes.total}
            style={{
              direction: t("css.side"),
              marginRight: t("css.side") === "rtl" ? 0 : 80,
            }}
          >
            <Link
              to="/checkout"
              className={classes.button}
              onClick={toggleCart}
            >
              {t("checkout.title")}
            </Link>
            <span className={classes.totalTitle}>{t("common.total")}</span>
            <span className={classes.totalNumber}>
              ₪{(data?.activeOrder?.subTotalWithTax / 100).toFixed(2)}
            </span>
          </div>
        </React.Fragment>
      ) : (
        <EmptyCart toggleCart={toggleCart} />
      )}
    </div>
  );
  return (
    <div className={classes.container}>
      {header}
      {cartLists}
    </div>
  );
};

const EmptyCart = ({ toggleCart }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.emptyContainer}>
      <div className={classes.title}>
        <span className={classes.empty}>{t("cart.Empty")}</span>
        <span className={classes.empty}>{t("cart.Check our catalog")}</span>
      </div>
      <EmptyIcon />
      <Link to="/catalog" className={classes.button} onClick={toggleCart}>
        {t("itemPage.catalog")}
      </Link>
    </div>
  );
};

export default CartDrawer;
