import React, { useContext, useEffect } from "react";

// import HomeCarousel from '../home/home-carousel/home-carousel.component';
import FirstImageSect from "./first-image-sect/first-image-sect";
import SecondCatalogSect from "./second-catalog-sect/second-catalog-sect";
import SixthAboutSect from "./sixth-about-sect/sixth-about-sect";
import OurSuppliers from "../common/our-suppliers/our-suppliers.component";
import HomeSeasonalProducts from "../home/home-seasonal-products/home-seasonal-products.component";
import { HeaderContext } from "../../context/headerContext";

export default function HomePage() {
  const { headerState, setHeaderState } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderState({
      ...headerState,
      color: "#FFFFFF",
      showCategories: false,
      showMainHeader: true,
      toggleFixed: false,
    });

    return () => {
      setHeaderState({
        ...headerState,
        color: "#15222F",
        showCategories: true,
        showMainHeader: true,
        toggleFixed: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ maxWidth: 1680, width: "100%", overflow: "hidden" }}>
      <FirstImageSect />
      <SixthAboutSect />
      <HomeSeasonalProducts />
      {/* hide it for now until we'll have a relevant engine for this  */}
      {/* <FourthPopularSect /> */}
      {/* <HomeCarousel /> */}
      <OurSuppliers />
    </div>
  );
}
