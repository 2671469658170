import { gql } from "@apollo/client";

const UPDATE_LANGUAGE = gql`
  mutation ($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      __typename
    }
  }
`;

export { UPDATE_LANGUAGE };
